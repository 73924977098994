import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import { Typography } from "@mui/material";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import CustomButton from "components/buttons/CustomButton";

dayjs.extend(quarterOfYear);

const DatePeriodSelector = ({
  onSelectPeriod,
  includePast = false,
  includeFuture = false,
}) => {
  const periods = [
    {
      label: "Προηγούμενο Εξάμηνο",
      calculateDates: () => ({
        dateFrom: dayjs().startOf("month").subtract(6, "month").toDate(),
        dateTo: dayjs().endOf("month").subtract(1, "month").toDate(),
      }),
      isDisabled: !includePast,
    },
    {
      label: "Προηγούμενο Τρίμηνο",
      calculateDates: () => ({
        dateFrom: dayjs().startOf("month").subtract(3, "month").toDate(),
        dateTo: dayjs().endOf("month").subtract(1, "month").toDate(),
      }),
      isDisabled: !includePast,
    },
    {
      label: "Προηγούμενος Μήνας",
      calculateDates: () => ({
        dateFrom: dayjs().startOf("month").subtract(1, "month").toDate(),
        dateTo: dayjs().endOf("month").subtract(1, "month").toDate(),
      }),
      isDisabled: !includePast,
    },
    {
      label: "Τρέχων Μήνας",
      calculateDates: () => ({
        dateFrom: dayjs().startOf("month").toDate(),
        dateTo: dayjs().toDate(),
      }),
      isDisabled: !includePast,
    },
    {
      label: "Επόμενος Μήνας",
      calculateDates: () => ({
        dateFrom: dayjs().startOf("month").add(1, "month").toDate(),
        dateTo: dayjs().endOf("month").add(1, "month").endOf("month").toDate(),
      }),
      isDisabled: !includeFuture,
    },
    {
      label: "Επόμενο Τρίμηνο",
      calculateDates: () => ({
        dateFrom: dayjs().startOf("month").add(1, "month").toDate(),
        dateTo: dayjs().endOf("month").add(3, "month").toDate(),
      }),
      isDisabled: !includeFuture,
    },
    {
      label: "Επόμενο Εξάμηνο",
      calculateDates: () => ({
        dateFrom: dayjs().startOf("month").add(1, "month").toDate(),
        dateTo: dayjs()
          .startOf("month")
          .add(6, "month")
          .endOf("month")
          .toDate(),
      }),
      isDisabled: !includeFuture,
    },
  ];

  return (
    <Box display="flex" gap={2} alignItems="center" justifyContent="flex-start">
      <Typography variant="h6">Επιλέξτε Περίοδο</Typography>
      <Box
        display="flex"
        gap={1}
        alignItems="center"
        flexWrap={"wrap"}
        justifyContent="flex-start"
      >
        {periods
          .filter((period) => !period.isDisabled)
          .map((period) => (
            <CustomButton
              key={period.label}
              title={period.label}
              onClick={() => {
                const { dateFrom, dateTo } = period.calculateDates();
                onSelectPeriod(dateFrom, dateTo);
              }}
              sx={{
                padding: "8px 16px",
              }}
              fontSize={13}
              fontWeight={400}
            >
              {period.label}
            </CustomButton>
          ))}
      </Box>
    </Box>
  );
};

DatePeriodSelector.propTypes = {
  onSelectPeriod: PropTypes.func.isRequired,
  includePast: PropTypes.bool,
  includeFuture: PropTypes.bool,
};

export default DatePeriodSelector;
