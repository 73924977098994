import enums from "config/enums";

const assetRequestFields = {
  general: [
    {
      label: "Ονομασία",
      name: "name",
      type: "text",
      required: true,
    },
    {
      label: "Α/Α",
      name: "number",
      type: "text",
      required: false,
    },
    {
      label: "ΚΑΕΚ",
      name: "kaek",
      type: "text",
      required: false,
    },
    {
      label: "Όροφοι",
      name: "floorCount",
      type: "number",
      required: false,
    },
    {
      label: "Επιφάνεια ακινήτου (τ.μ.)",
      name: "areaSize",
      type: "number",
      required: false,
    },
    {
      label: "Χρήση ακινήτου",
      name: "assetUse.description",
      type: "select",
      required: false,
      options: enums.AssetUse?.map((use) => use.label),
    },
    {
      label: "Κτηματολογική αποτύπωση",
      name: "landSurvey",
      type: "text",
      required: false,
    },
    {
      label: "Δημοτική Ενότητα",
      name: "municipalitySector",
      type: "text",
      required: true,
    },
    {
      label: "Παρατηρήσεις",
      name: "generalNotes",
      type: "textarea",
      required: false,
    },
    {
      label: "Φωτογραφίες",
      name: "generalFiles",
      type: "file",
      description: "Επισύναψη φωτογραφιών από επιτόπιο έλεγχο",
      multiple: true,
      validTypes: ["image/jpeg", "image/png"],
      maxSize: 5000000,
    },
    {
      label: "Τοποθεσία",
      name: "geoLocation",
      type: "geoLocation",
      required: true,
    },
  ],
  legal: [
    {
      label: "Ιδιοκτησία",
      name: "complianceAudit.ownership",
      type: "select",
      required: false,
      options: enums.Ownership?.map((option) => option.label),
    },
    {
      label: "Είδος Κυριότητας",
      name: "complianceAudit.ownershipType",
      type: "select",
      required: false,
      options: enums.OwnershipType?.map((option) => option.label),
    },
    {
      label: "Ποσοστό Ιδιοκτησίας",
      name: "complianceAudit.ownershipPercentage",
      type: "number",
      required: false,
    },
    {
      label: "Αξία Ακινήτου",
      name: "complianceAudit.propertyValue",
      type: "number",
      required: false,
    },
    {
      label: "Αρ. Σύμβασης",
      name: "complianceAudit.contractNumber",
      type: "text",
      required: false,
    },
    {
      label: "Παρατηρήσεις",
      name: "complianceAudit.notes",
      type: "select",
      required: false,
      options: enums.LegalControlNotesOptions?.map((option) => option.label),
    },
    {
      label: "Πρόσθετες Παρατηρήσεις",
      name: "complianceAudit.otherOptionNote",
      type: "textarea",
      required: false,
    },
    {
      label: "Συνοδευτικά Έγγραφα",
      name: "complianceFiles",
      type: "file",
      multiple: true,
      validTypes: ["image/jpeg", "image/png", "application/pdf"],
      maxSize: 5000000,
    },
  ],
  financial: [
    {
      label: "Είναι εμπορικά εκμεταλλεύσιμο;",
      name: "financialAudit.isProfitable",
      type: "boolean",
      required: false,
    },
    {
      label: "Τιμή εμπορικής ζώνης",
      name: "financialAudit.commercialZonePrice",
      type: "number",
      required: false,
    },
    {
      label: "Απαιτείται δήλωση Ε9;",
      name: "financialAudit.isPendingE9",
      type: "boolean",
      required: false,
    },
    {
      label: "Έχει δηλωθεί;",
      name: "financialAudit.isDeclaredE9",
      type: "boolean",
      required: false,
    },
    {
      label: "Απαιτείται συμπερίληψη στην έκθεση ορκωτού;",
      name: "financialAudit.isControlStatementNeeded",
      type: "boolean",
      required: false,
    },
    {
      label: "Έχει συμπεριληφθεί;",
      name: "financialAudit.includeControlStatement",
      type: "boolean",
      required: false,
    },
    {
      label: "Παρατηρήσεις",
      name: "financialAudit.economicalControlNotesOptions",
      type: "select",
      required: false,
      options: enums.EconomicalControlNotesOptions,
    },
    {
      label: "Πρόσθετες Παρατηρήσεις",
      name: "financialAudit.otherOptionNote",
      type: "textarea",
      required: false,
    },
    {
      label: "Συνοδευτικά Έγγραφα",
      name: "financialFiles",
      type: "file",
      multiple: true,
      validTypes: ["image/jpeg", "image/png", "application/pdf"],
      maxSize: 5000000,
    },
  ],
  technical: [
    {
      label: "Συνολικός όγκος κτιρίου (κ.μ.)",
      name: "technicalControl.buildingVolume",
      type: "number",
      required: false,
    },
    {
      label: "Συνολικός ωφέλιμος όγκος κτιρίου (κ.μ.)",
      name: "technicalControl.usedVolume",
      type: "number",
      required: false,
    },
    {
      label: "Ημερομηνία κατασκευής",
      name: "technicalControl.constructionDate",
      type: "date",
      yearOnly: true,
      required: false,
    },
    {
      label: "Αριθμός παροχής ηλεκτρικού ρεύματος",
      name: "technicalControl.powerSupplyNumber",
      type: "text",
      required: false,
    },
    {
      label: "Είναι εν λειτουργία το κτίριο;",
      name: "technicalControl.inUse",
      type: "boolean",
      required: false,
    },
    {
      label: "Ανακαίνιση μικρής κλίμακας",
      name: "technicalControl.smallRenovation",
      type: "boolean",
      required: false,
    },
    {
      label: "Έτος ανακαίνισης μικρής κλίμακας",
      name: "technicalControl.lastSmallRenovationDate",
      type: "date",
      yearOnly: true,
      required: false,
    },
    {
      label: "Ριζική ανακαίνιση",
      name: "technicalControl.bigRenovation",
      type: "boolean",
      required: false,
    },
    {
      label: "Έτος ριζικής ανακαίνισης",
      name: "technicalControl.lastBigRenovationDate",
      type: "date",
      yearOnly: true,
      required: false,
    },
    {
      label: "Μόνιμοι χρήστες σε ημερήσια βάση",
      name: "technicalControl.usersCount",
      type: "number",
      required: false,
    },
    {
      label: "Επισκπέπτες σε ημερήσια βάση",
      name: "technicalControl.visitorsCount",
      type: "number",
      required: false,
    },
    {
      label: "Αναγνωριστικός αριθμός οικοδομικής άδειας",
      name: "technicalControl.legalConstruction",
      type: "text",
      required: false,
    },
    {
      label: "Διαθέτει άδεια λειτουργίας",
      name: "technicalControl.operatingLicense",
      type: "boolean",
      required: false,
    },
    {
      label: "Υπεύθυνος σύμβασης",
      name: "technicalControl.contractPerson",
      type: "text",
      required: false,
    },
    {
      label: "Διαθέτει ιατρείο",
      name: "technicalControl.hasMedicalRoom",
      type: "boolean",
      required: false,
    },
    {
      label: "Πολεοδομικός Χαρακτήρας",
      name: "technicalControl.cityPlanningCharacter",
      type: "select",
      required: false,
      options: enums.CityPlanningCharacter?.map((option) => option.label),
    },
  ],
  energy: [
    {
      label: "Κατηγορία ενεργειακής απόδοσης κτιρίου",
      name: "energyControl.buildingPerformanceCategory",
      type: "text",
      required: false,
    },
    {
      label: "ΠΕΑ",
      name: "energyControl.pea",
      type: "text",
      required: false,
    },
    {
      label: "Ετήσια αναφερόμενη ενεργειακή κατανάλωση",
      name: "energyControl.annualReferenceEnergyConsumption",
      type: "number",
      required: false,
    },
    {
      label: "Ετήσια ενεργειακή κατανάλωση",
      name: "energyControl.annualEnergyConsumption",
      type: "number",
      required: false,
    },
    {
      label: "Εκπομπές CO2",
      name: "energyControl.cO2Emissions",
      type: "number",
      required: false,
    },
    {
      label: "Κλάση ενεργειακής απόδοσης",
      name: "energyControl.energyEfficiencyClass",
      type: "text",
      required: false,
    },
    {
      label: "Περιγραφή συστήματος θέρμανσης",
      name: "energyControl.heatingSystemDescription",
      type: "text",
      required: false,
    },
    {
      label: "Επιλογή καυσίμου συστήματος θέρμανσης",
      name: "energyControl.heatingSystemCombustibleOption",
      type: "select",
      required: false,
      options: enums.Combustible,
    },
    {
      label: "Άλλη επιλογή καυσίμου συστήματος θέρμανσης",
      name: "energyControl.heatingSystemCombustibleOptionOther",
      type: "text",
      required: false,
    },
    {
      label: "Ισχύς συστήματος θέρμανσης (kW)",
      name: "energyControl.heatingSystemPower",
      type: "number",
      required: false,
    },
    {
      label: "Απόδοση συστήματος θέρμανσης (%)",
      name: "energyControl.heatingSystemPerformance",
      type: "number",
      required: false,
    },
    {
      label: "Περιγραφή συστήματος ψύξης",
      name: "energyControl.coolingSystemDescription",
      type: "text",
      required: false,
    },
    {
      label: "Επιλογή καυσίμου συστήματος ψύξης",
      name: "energyControl.coolingSystemCombustibleOption",
      type: "select",
      required: false,
      options: enums.Combustible,
    },
    {
      label: "Ισχύς συστήματος ψύξης (kW)",
      name: "energyControl.coolingSystemPower",
      type: "number",
      required: false,
    },
    {
      label: "Απόδοση συστήματος ψύξης (%)",
      name: "energyControl.coolingSystemPerformance",
      type: "number",
      required: false,
    },
    {
      label: "Εξοπλισμός ανανεώσιμων πηγών ενέργειας",
      name: "energyControl.renewableEnergyEquipment",
      type: "text",
      required: false,
    },
    {
      label: "Ετήσια κατανάλωση ενέργειας επιφάνειας (kWh/m2)",
      name: "energyControl.annualConsumptionSurface",
      type: "number",
      required: false,
    },
    {
      label: "Ετήσια κατανάλωση ενέργειας όγκου (kWh/m3)",
      name: "energyControl.annualConsumptionVolume",
      type: "number",
      required: false,
    },
    {
      label: "Ετήσιο κόστος κατανάλωσης",
      name: "energyControl.annualConsumptionCost",
      type: "number",
      required: false,
    },
  ],
};

export default assetRequestFields;
