import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import colors from "config/theme/colors";

const GeneralModal = ({ title, description, onClose, onConfirm, isOpen }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="exit-modal-title"
      aria-describedby="exit-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: 300, sm: 400 },
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          textAlign: "center",
        }}
      >
        <Stack spacing={2} alignItems="center">
          <WarningAmberIcon color="warning" sx={{ fontSize: 48 }} />
          <Typography
            id="exit-modal-title"
            variant="h5"
            component="h2"
            gutterBottom
          >
            {title}
          </Typography>
          <Typography
            id="exit-modal-description"
            variant="body1"
            color="text.secondary"
          >
            {description}
          </Typography>
          <Box display="flex" justifyContent="center" gap={2}>
            <Button
              onClick={onConfirm}
              sx={{
                minWidth: 100,
                "&:hover": {
                  backgroundColor: colors.primary,
                  opacity: 0.8,
                },
              }}
            >
              Ναι
            </Button>
            <Button
              onClick={onClose}
              sx={{
                minWidth: 100,
                "&:hover": {
                  backgroundColor: colors.primary,
                  opacity: 0.8,
                },
              }}
            >
              Όχι
            </Button>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
};

GeneralModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default GeneralModal;
