import { requests } from "api/HttpClient";

const { post, get, put, delete: deleteRequest } = requests;

const equipment = {
  getEquipmentList: ({
    pageNumber = -1,
    pageSize = -1,
    assetId,
    assetCategoryId,
    equipmentTypeIds,
    searchParameter,
    sortingOrder,
    orderBy,
  }) => {
    let url = "/Equipment/GetEquipmentList";

    const queryParams = [];

    if (pageNumber && pageSize) {
      queryParams.push(`pageNumber=${pageNumber}`);
      queryParams.push(`pageSize=${pageSize}`);
    }

    if (searchParameter) {
      queryParams.push(`searchParameter=${searchParameter}`);
    }

    if (orderBy) {
      queryParams.push(`orderBy=${orderBy}`);
    }

    if (sortingOrder) {
      queryParams.push(`sortingOrder=${sortingOrder}`);
    }

    if (assetId) {
      queryParams.push(`assetId=${assetId}`);
    }

    if (assetCategoryId) {
      queryParams.push(`assetCategoryId=${assetCategoryId}`);
    }

    if (equipmentTypeIds) {
      equipmentTypeIds.forEach((equipmentType) => {
        queryParams.push(
          `EquipmentTypeIds=${encodeURIComponent(equipmentType)}`
        );
      });
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }

    return get(url);
  },
  getEquipmentById: (id) => get(`/Equipment/GetEquipmentById/${id}`),
  getEquipmentTypeCount: () => get("/Equipment/GetEquipmentTypeCount"),
  createEquipment: (body) =>
    post("/Equipment/AddEquipment", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  updateEquipment: (id, body) =>
    put(`/Equipment/UpdateEquipment/${id}`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  deleteEquipment: (id) => deleteRequest(`/Equipment/DeleteEquipment/${id}`),
};

export default equipment;
