import { Box, Typography } from "@mui/material";
import CustomButton from "components/buttons/CustomButton";
import CustomTable from "components/tables/CustomTable";
import { useNavigate } from "react-router";
import { useMemo } from "react";
import colors from "config/theme/colors";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import { useGetAllMaintenancesHistory } from "hooks/queries/useMaintenancesHistory";
import SpinningCircle from "components/spinners/SpinningCircle";
import PropTypes from "prop-types";
import { formatNumbersWithSeparator } from "utils/formatNumbersWithSeperator";
import usePagination from "hooks/usePagination";
import dayjs from "dayjs";

const MaintenanceHistoryOverview = ({ maintenanceId }) => {
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination();
  const navigate = useNavigate();

  const {
    data: maintenancesHistory,
    isLoading: isMaintenancesHistoryLoading,
    error: errorMaintenancesHistory,
  } = useGetAllMaintenancesHistory({
    pageNumber: page,
    pageSize: rowsPerPage,
    maintenanceId: maintenanceId,
  });

  const tableHeaders = useMemo(
    () => [
      {
        id: "name",
        label: "Αναγνωριστικό",
        isSortable: false,
      },
      {
        id: "date",
        label: "Ημερομηνία",
        isSortable: false,
      },
      {
        id: "amount",
        label: "Ποσό (€)",
        isSortable: false,
      },
      {
        id: "contract",
        label: "Σύμβαση",
        isSortable: false,
      },
    ],
    []
  );

  if (isMaintenancesHistoryLoading) {
    return <SpinningCircle />;
  }

  if (errorMaintenancesHistory) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {errorMaintenancesHistory?.message}</p>
      </Box>
    );
  }

  const maintenancesHistoryCount = maintenancesHistory?.totalCount;
  const maintenancesHistoryList = maintenancesHistory?.items?.map(
    (history) => ({
      ...history,
      name: history?.name || "-",
      date: dayjs(history?.date).format("DD/MM/YYYY") || "-",
      amount: formatNumbersWithSeparator(history?.amount) || "-",
      contract: history?.contract?.name || "-",
    })
  );

  const handleItemClick = (item) => {
    navigate(`history/${item.id}`);
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginLeft={3}
        gap={13}
      >
        <Typography fontSize={20} fontWeight={600} color={colors.secondary}>
          Ιστορικό Συντήρησης
        </Typography>

        <CustomButton
          icon={<AddIcon fill={colors.primary} width={20} height={20} />}
          title="Προσθήκη"
          orientation="row"
          fontSize={13}
          sx={{
            minHeight: 30,
            maxHeight: 30,
            minWidth: 160,
            maxWidth: 160,
            borderRadius: 3,
            paddingY: 2.3,
          }}
          onClick={() => {
            navigate("history/new");
          }}
        />
      </Box>

      <Box marginLeft={3}>
        {maintenancesHistoryList?.length > 0 &&
        tableHeaders &&
        maintenancesHistoryCount > 0 ? (
          <CustomTable
            columns={tableHeaders}
            data={maintenancesHistoryList}
            fadeEffect
            onItemClick={handleItemClick}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalResults={maintenancesHistoryCount}
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
              marginTop: "18%",
            }}
          >
            <Typography variant="h4">Δεν υπάρχουν αποτελέσματα</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

MaintenanceHistoryOverview.propTypes = {
  maintenanceId: PropTypes.string.isRequired,
};

export default MaintenanceHistoryOverview;
