import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SearchBar from "components/inputs/SearchBar";
import SpinningCircle from "components/spinners/SpinningCircle";
import CustomTable from "components/tables/CustomTable";
import { useMemo, useState, useCallback } from "react";
import { useNavigate } from "react-router";
import colors from "config/theme/colors";
import MainPageLayout from "components/main/MainPageLayout";
import { ReactComponent as TaskIcon } from "assets/icons/task.svg";
import { useGetAllTasks, useGetTaskCount } from "hooks/queries/useTasks";
import useSearchInput from "hooks/useSearchInput";
import { getTaskFontWeight } from "utils/getTaskFontWeight";
import { getTaskBackgroundColor } from "utils/getTaskBackgroundColor";
import { getTaskColor } from "utils/getTaskColor";
import { formatDateTime } from "utils/formatDateTime";
import CountCard from "components/cards/CountCard";
import useSorting from "hooks/useSorting";
import usePagination from "hooks/usePagination";
import FilterButton from "components/buttons/FilterButton";
import enums from "config/enums";
import { ReactComponent as AlertIcon } from "assets/icons/alerts.svg";

const colorPalette = [colors.info, colors.taskPending, "#138F36", colors.error];

const TasksOverview = () => {
  const navigate = useNavigate();
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedTaskStatuses, setSelectedTaskStatuses] = useState([]);
  const {
    page,
    setPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination();
  const { orderDirection, valueToOrderBy, handleSortChange } = useSorting();
  const { searchInput, handleSearch } = useSearchInput();
  const {
    data: tasks,
    isLoading: isTasksLoading,
    error: errorTasks,
    refetch: refetchTasks,
  } = useGetAllTasks({
    pageNumber: page,
    pageSize: rowsPerPage,
    searchParameter: searchInput,
    orderBy: valueToOrderBy,
    sortingOrder: orderDirection === "asc" ? "Ascending" : "Descending",
    scheduledTaskStatus: selectedTaskStatuses,
  });
  const {
    data: tasksStatusesCount,
    isLoading: isTasksCountLoading,
    error: errorTasksCount,
  } = useGetTaskCount();

  const renderTaskTypography = (task, content) => {
    const fontWeight = getTaskFontWeight(task);
    return (
      <Typography fontSize={14} fontWeight={fontWeight}>
        {content}
      </Typography>
    );
  };

  const tableHeaders = useMemo(
    () => [
      {
        id: "icon",
        label: "",
        isSortable: false,
      },
      {
        id: "codeNumber",
        label: "Κωδικός",
        isSortable: false,
      },
      {
        id: "name",
        label: "Τίτλος",
        isSortable: false,
      },
      {
        id: "asset",
        label: "Ακίνητο",
        isSortable: false,
      },
      {
        id: "equipment",
        label: "Εξοπλισμός",
        isSortable: false,
      },
      {
        id: "expiresAt",
        label: "Αναμενόμενη Ολοκλήρωση Μέχρι",
        isSortable: false,
      },
      {
        id: "completedAt",
        label: "Ημ/νία Ολοκλήρωσης",
        isSortable: false,
      },
      {
        id: "entityCreatedAt",
        label: "Ημ/νία Δημιουργίας",
        isSortable: true,
      },
      {
        id: "status",
        label: "Κατάσταση",
        isSortable: false,
      },
    ],
    []
  );

  const filterGroups = [
    {
      title: "Κατάσταση Εργασίας",
      filters: enums.TaskStatuses.map((status) => ({
        id: status.id,
        label: status.label,
        type: "checkbox",
      })),
    },
  ];

  const handleSubmitFilters = useCallback(
    (filters) => {
      const taskStatuses = filters["Κατάσταση Εργασίας"] || [];
      setSelectedFilters(filters);
      setSelectedTaskStatuses(taskStatuses);
      if (filters) {
        setPage(1);
      }

      if (taskStatuses.length === 0) {
        refetchTasks();
      }
    },
    [setSelectedFilters, setSelectedTaskStatuses, setPage, refetchTasks]
  );

  const tasksCount = tasks?.totalCount;
  const tasksList = tasks?.items?.map((task) => ({
    ...task,
    icon: <TaskIcon fill={colors.white} width={30} height={30} />,
    codeNumber: renderTaskTypography(task, task?.id || "-"),
    name: renderTaskTypography(task, task?.name || "-"),
    id: task?.id,
    asset: renderTaskTypography(
      task,
      task?.maintenance?.asset?.name ||
        task?.maintenance?.equipment?.asset?.name ||
        task?.rent?.asset?.name ||
        "-"
    ),
    equipment: renderTaskTypography(
      task,
      task?.maintenance?.equipment?.name || "-"
    ),
    status: (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding={"6px 12px"}
        sx={{
          backgroundColor: getTaskBackgroundColor(task?.status),
          borderRadius: 2,
        }}
      >
        <Typography
          color={getTaskColor(task?.status)}
          fontWeight={500}
          fontSize={14}
          textAlign={"center"}
        >
          {task?.status}
        </Typography>
      </Box>
    ),
    entityCreatedAt: renderTaskTypography(
      task,
      formatDateTime(task?.entityCreatedAt, true)
    ),
    completedAt: renderTaskTypography(
      task,
      formatDateTime(task?.completedAt, true)
    ),
    expiresAt: renderTaskTypography(
      task,
      formatDateTime(task?.expiresAt, true)
    ),
    notes: renderTaskTypography(task, task?.notes || "-"),
  }));

  const handleItemClick = (item) => {
    navigate(`${item.id}`);
  };

  if (isTasksLoading || isTasksCountLoading) {
    return <SpinningCircle />;
  }

  if (errorTasks || errorTasksCount) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {errorTasks?.message || errorTasksCount?.message}</p>
      </Box>
    );
  }

  return (
    <MainPageLayout pageTitle="Εργασίες">
      <Box
        display="flex"
        gap={2}
        flexWrap={{
          xs: "wrap",
          sm: "nowrap",
        }}
        sx={{
          overflowX: "auto",
        }}
      >
        {tasksStatusesCount?.map((statusItem) => {
          const count = statusItem.count;
          return (
            <CountCard
              key={statusItem.name}
              title={statusItem.name}
              count={count}
              backgroundColor={
                colorPalette[tasksStatusesCount?.indexOf(statusItem)]
              }
              icon={<AlertIcon fill={colors.white} width={30} height={30} />}
              boxSx={{
                minWidth: { xs: "100%", sm: "250px" },
              }}
            />
          );
        })}
      </Box>
      <Box
        mt={4}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent={{
          xs: "space-between",
          lg: "flex-start",
        }}
        flexGrow={1}
        gap={2}
        height={40}
      >
        <SearchBar
          placeholder="Αναζήτηση σε Τίτλο"
          handleSearch={handleSearch}
          searchKey={searchInput}
        />
      </Box>
      <Box
        mt={4.5}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent={{
          xs: "space-between",
          lg: "space-between",
        }}
        height={40}
      >
        <Typography variant="h3" color="primary">
          Εργασίες ({tasksCount})
        </Typography>
        <FilterButton
          selectedFilters={selectedFilters}
          filterGroups={filterGroups}
          onApplyFilters={handleSubmitFilters}
        />
      </Box>

      <Box mt={4}>
        {tasksList.length > 0 && tableHeaders && tasksCount > 0 ? (
          <CustomTable
            columns={tableHeaders}
            data={tasksList}
            fadeEffect
            onItemClick={handleItemClick}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalResults={tasksCount}
            orderDirection={orderDirection}
            valueToOrderBy={valueToOrderBy}
            onSortChange={handleSortChange}
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
            }}
          >
            <Typography variant="h4">Δεν υπάρχουν αποτελέσματα</Typography>
          </Box>
        )}
      </Box>
    </MainPageLayout>
  );
};

export default TasksOverview;
