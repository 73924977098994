import { Box, Button, Typography } from "@mui/material";
import FilterButton from "components/buttons/FilterButton";
import CountCard from "components/cards/CountCard";
import SearchBar from "components/inputs/SearchBar";
import TagLabel from "components/labels/TagLabel";
import MainPageLayout from "components/main/MainPageLayout";
import SpinningCircle from "components/spinners/SpinningCircle";
import CustomTable from "components/tables/CustomTable";
import dayjs from "dayjs";
import {
  useGetAllEquipment,
  useGetEquipmentTypeCount,
} from "hooks/queries/useEquipment";
import { useGetAllEquipmentTypes } from "hooks/queries/useEquipmentType";
import usePagination from "hooks/usePagination";
import useSearchInput from "hooks/useSearchInput";
import useSorting from "hooks/useSorting";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatNumbersWithSeparator } from "utils/formatNumbersWithSeperator";
import HomeRepairServiceOutlinedIcon from "@mui/icons-material/HomeRepairServiceOutlined";
import colors from "config/theme/colors";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const colorPalette = [
  "#FF7A00", // Vibrant Orange
  "#313131", // Dark Gray
  "#6A41AD", // Deep Purple
  "#279500", // Rich Green
  "#0077CC", // Bright Blue
  "#FFC400", // Bright Yellow
  "#FF3B30", // Bright Red
  "#FF9500", // Bright Orange
  "#4CD964", // Bright Green
  "#5AC8FA", // Bright Blue
  "#5856D6", // Bright Purple
  "#FF2D55", // Bright Pink
  "#FFD700", // Bright Yellow
  "#FF1493", // Bright Pink
  "#FF4500", // Bright Orange
  "#FF8C00", // Bright Orange
  "#FF69B4", // Bright Pink
  "#FF6347", // Bright Orange
];

const GeneralEquipmentOverview = () => {
  const navigate = useNavigate();
  const [selectedEquipmentTypes, setSelectedEquipmentTypes] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const { searchInput, handleSearch } = useSearchInput();
  const { orderDirection, valueToOrderBy, handleSortChange } = useSorting();
  const {
    page,
    setPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination();
  const {
    data: paginatedEquipments,
    isLoading: paginatedEquipmentsLoading,
    error: paginatedEquipmentsError,
    refetch: refetchPaginatedEquipments,
  } = useGetAllEquipment({
    pageNumber: page,
    pageSize: rowsPerPage,
    searchParameter: searchInput,
    equipmentTypeIds: selectedEquipmentTypes,
    orderBy: valueToOrderBy,
    sortingOrder: orderDirection === "asc" ? "Ascending" : "Descending",
  });
  const {
    data: equipmentTypes,
    isLoading: isLoadingEquipmentTypes,
    error: errorEquipmentTypes,
  } = useGetAllEquipmentTypes();
  const {
    data: equipmentTypesCount,
    isLoading: isLoadingEquipmentTypesCount,
    isError: isErrorEquipmentTypesCount,
    error: errorEquipmentTypesCount,
  } = useGetEquipmentTypeCount();

  const filterGroups = [
    {
      title: "Τύπος Εξοπλισμού",
      filters: equipmentTypes?.items
        ?.filter(
          (equipmentType) =>
            equipmentTypesCount?.find(
              (item) => item.name === equipmentType.name
            )?.count > 0
        )
        ?.map((equipmentType) => ({
          id: equipmentType?.id,
          label: equipmentType?.name,
          type: "checkbox",
        })),
    },
  ];

  const handleSubmitFilters = useCallback(
    (filters) => {
      const equipmentTypes = filters["Τύπος Εξοπλισμού"] || [];
      setSelectedFilters(filters);
      setSelectedEquipmentTypes(equipmentTypes);
      if (filters) {
        setPage(1);
      }

      if (equipmentTypes.length === 0) {
        refetchPaginatedEquipments();
      }
    },
    [
      setSelectedEquipmentTypes,
      setSelectedFilters,
      refetchPaginatedEquipments,
      setPage,
    ]
  );

  const tableHeaders = useMemo(
    () => [
      {
        id: "color",
        label: "",
        isSortable: false,
      },
      {
        id: "name",
        label: "Όνομα",
        isSortable: true,
      },
      {
        id: "assetName",
        label: "Ακίνητο",
        isSortable: true,
      },
      {
        id: "equipmentType",
        label: "Τύπος",
        isSortable: true,
      },
      {
        id: "nextMaintenanceDate",
        label: "Επόμενη Συντήρηση",
        isSortable: true,
      },
      {
        id: "maintenanceOrganization",
        label: "Οργανισμός Συντήρησης",
        isSortable: true,
      },
      {
        id: "floor",
        label: "Όροφος",
        isSortable: false,
      },
    ],
    []
  );

  if (
    paginatedEquipmentsLoading ||
    isLoadingEquipmentTypes ||
    isLoadingEquipmentTypesCount
  ) {
    return <SpinningCircle />;
  }

  if (
    paginatedEquipmentsError ||
    errorEquipmentTypes ||
    isErrorEquipmentTypesCount
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>
          Error:{" "}
          {paginatedEquipmentsError.message ||
            errorEquipmentTypes.message ||
            errorEquipmentTypesCount.message}
        </p>
      </Box>
    );
  }

  const getEquipments = (equipments) => {
    return equipments?.map((equipment) => {
      const equipmentType =
        equipment.equipmentClass?.equipmentGroup?.equipmentType;
      const typeIndex = equipmentTypes.items.findIndex(
        (item) => item.id === equipmentType?.id
      );
      const typeColor = colorPalette[typeIndex % colorPalette.length];
      const color = () => (
        <Box
          display="flex"
          justifyContent="start"
          alignItems="start"
          height={"100%"}
        >
          <Box width={8} height={8} borderRadius="50%" bgcolor={typeColor} />
        </Box>
      );

      return {
        id: equipment.id,
        colorCode: typeColor,
        color: color(),
        name: equipment.name,
        equipmentType: equipmentType?.name || "-",
        typeId: equipmentType?.id,
        assetName: equipment.asset?.name ? (
          <Button
            onClick={(e) => {
              navigate(`/assets/${equipment.assetId}`);
              e.stopPropagation();
            }}
            sx={{
              textTransform: "none",
              color: colors.primary,
              backgroundColor: "transparent",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <span
              style={{
                textAlign: "left",
                wordBreak: "break-word",
              }}
            >
              {equipment.asset?.name}{" "}
            </span>
            <OpenInNewIcon
              sx={{
                fontSize: 16,
              }}
            />
          </Button>
        ) : (
          "-"
        ),
        nextMaintenanceDate: equipment.nextMaintenanceDate
          ? dayjs(equipment.nextMaintenanceDate).format("DD/MM/YYYY")
          : "-",
        maintenanceOrganization: equipment.maintenanceCompany?.name ? (
          <Button
            onClick={(e) => {
              navigate(`/companies/${equipment.maintenanceCompany.id}`);
              e.stopPropagation();
            }}
            sx={{
              textTransform: "none",
              color: colors.primary,
              backgroundColor: "transparent",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <span
              style={{
                textAlign: "left",
                wordBreak: "break-word",
              }}
            >
              {equipment.maintenanceCompany?.name}
            </span>
            <OpenInNewIcon
              sx={{
                fontSize: 16,
              }}
            />
          </Button>
        ) : (
          "-"
        ),
        floor: formatNumbersWithSeparator(equipment.floor || "-"),
      };
    });
  };

  const equipmentsCount = paginatedEquipments?.totalCount;

  const equipmentList = getEquipments(paginatedEquipments?.items);

  const handleItemClick = (item) => {
    const selectedEquipment = paginatedEquipments?.items.find(
      (equipment) => equipment.id === item.id
    );

    navigate(
      `/assets/${selectedEquipment.assetId}/equipment/${selectedEquipment.id}/general`
    );
  };

  return (
    <MainPageLayout pageTitle="Εξοπλισμοί">
      <Box
        display="flex"
        flexDirection={{
          xs: "column",
          sm: "row",
        }}
        justifyContent={{
          xs: "space-between",
        }}
        flexGrow={1}
        flexWrap={{
          xs: "wrap",
          md: "nowrap",
        }}
        width={"100%"}
        gap={2}
      >
        <Box
          display="flex"
          gap={2}
          flexWrap={{
            xs: "wrap",
            sm: "nowrap",
          }}
          sx={{
            overflowX: "auto",
          }}
        >
          {equipmentTypes?.items?.map((equipmentType) => {
            const typeData = equipmentTypesCount?.find(
              (item) => item.name === equipmentType.name
            );
            const count = typeData?.count || 0;

            if (count === 0) {
              return null;
            }

            return (
              <CountCard
                key={equipmentType.id}
                title={equipmentType.name}
                backgroundColor={
                  colorPalette[equipmentTypes.items.indexOf(equipmentType)]
                }
                count={count}
                icon={
                  <HomeRepairServiceOutlinedIcon
                    sx={{
                      width: 35,
                      height: 35,
                    }}
                  />
                }
                boxSx={{
                  minWidth: { xs: "100%", sm: "250px" },
                }}
              />
            );
          })}
        </Box>
      </Box>
      <Box
        mt={4}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent={{
          xs: "space-between",
          lg: "flex-start",
        }}
        flexGrow={1}
        gap={2}
        height={40}
      >
        <SearchBar
          placeholder="Αναζήτηση σε Όνομα, Ακίνητο, Τύπο"
          handleSearch={handleSearch}
          searchKey={searchInput}
        />
      </Box>
      <Box
        mt={4}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flexGrow={1}
        width={"100%"}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="start"
          alignItems="center"
          gap={4}
          flexWrap="wrap"
        >
          <Typography variant="h3" color="primary">
            Εξοπλισμοί ({equipmentsCount})
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            gap={1}
            flexWrap="wrap"
            flexGrow={1}
          >
            {equipmentTypes?.items.map((assetCategory) => {
              const count = equipmentList?.filter(
                (assetItem) => assetItem.categoryId === assetCategory.id
              ).length;
              return count > 0 ? (
                <TagLabel
                  key={assetCategory.id}
                  text={`${count} ${assetCategory.description}`}
                />
              ) : null;
            })}
          </Box>
          <Box display="flex" flexGrow={1} justifyContent="flex-end">
            <FilterButton
              selectedFilters={selectedFilters}
              filterGroups={filterGroups}
              onApplyFilters={handleSubmitFilters}
            />
          </Box>
        </Box>

        {equipmentsCount === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
            }}
          >
            <Typography variant="h4">Δεν υπάρχουν αποτελέσματα</Typography>
          </Box>
        ) : (
          <CustomTable
            columns={tableHeaders}
            data={equipmentList}
            fadeEffect
            onItemClick={handleItemClick}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalResults={equipmentsCount}
            orderDirection={orderDirection}
            valueToOrderBy={valueToOrderBy}
            onSortChange={handleSortChange}
          />
        )}
      </Box>
    </MainPageLayout>
  );
};

export default GeneralEquipmentOverview;
