import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import colors from "config/theme/colors";
import DateField from "components/inputs/DateField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";
import "dayjs/locale/el";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);
dayjs.locale("el");

const BypassNextMaintenanceModal = ({ isOpen, onClose, onBypass }) => {
  const schema = yup.object({
    manualDate: yup
      .date()
      .nullable()
      .transform(function (value, originalValue) {
        if (this.isType(value)) return value;
        const parsedDate = dayjs(value, "YYYY-MM-DD", true);
        return parsedDate.isValid() ? parsedDate.toDate() : new Date("");
      })
      .typeError("Η ημερομηνία δεν είναι έγκυρη")
      .required("Η ημερομηνία είναι υποχρεωτική")
      .min(
        dayjs().add(1, "day").startOf("day").toDate(),
        "Η ημερομηνία πρέπει να είναι μετά την σημερινή"
      ),
  });

  const {
    handleSubmit,
    control,
    formState: { isValid },
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const handleConfirmBypass = () => {
    const manualDate = getValues("manualDate");
    const utcDate = dayjs(manualDate).utc().format("YYYY-MM-DDTHH:mm:ssZ");
    onBypass(utcDate);
    reset();
    onClose();
  };

  const handleCancelBypass = () => {
    reset();
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleCancelBypass}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: 300, sm: 400 },
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          textAlign: "center",
        }}
      >
        <Stack spacing={2} alignItems="center">
          <WarningAmberIcon color="warning" sx={{ fontSize: 48 }} />
          <Typography
            id="exit-modal-title"
            variant="h5"
            component="h2"
            gutterBottom
          >
            Χειροκίνητη επιλογή επόμενης συντήρησης (Επιλογή Ημερομηνίας)
          </Typography>
          <Typography
            id="exit-modal-description"
            variant="body1"
            color="text.secondary"
          >
            Επιλέξτε την επιθυμητή ημερομηνία για την επόμενη συντήρηση
          </Typography>

          <form onSubmit={handleSubmit(handleConfirmBypass)}>
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              alignItems="center"
            >
              <DateField
                name="manualDate"
                control={control}
                min={dayjs().add(1, "day").toDate()}
              />

              <Box display="flex" justifyContent="center" gap={2}>
                <Button
                  onClick={handleConfirmBypass}
                  type="submit"
                  disabled={!isValid}
                  sx={{
                    minWidth: 100,
                    "&:hover": {
                      backgroundColor: colors.primary,
                      opacity: 0.8,
                    },
                    "&:disabled": {
                      color: colors.white,
                      backgroundColor: colors.primary,
                      opacity: 0.5,
                    },
                  }}
                >
                  Επιβεβαίωση
                </Button>
                <Button
                  onClick={handleCancelBypass}
                  sx={{
                    minWidth: 100,
                    "&:hover": {
                      backgroundColor: colors.primary,
                      opacity: 0.8,
                    },
                  }}
                >
                  Ακύρωση
                </Button>
              </Box>
            </Box>
          </form>
        </Stack>
      </Box>
    </Modal>
  );
};

BypassNextMaintenanceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onBypass: PropTypes.func.isRequired,
};

export default BypassNextMaintenanceModal;
