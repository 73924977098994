import React from "react";
import PropTypes from "prop-types";
import SidebarMenu from "pages/sidebar/SidebarMenu";
import { Box } from "@mui/material";
import colors from "config/theme/colors";
import { useSidebar } from "hooks/useSidebar";
import { observer } from "mobx-react-lite";
import CountdownTimer from "components/alerts/CountdownTimer";

const AuthenticatedContent = ({ children }) => {
  const { isCollapsed } = useSidebar();

  return (
    <Box
      display="flex"
      width={1}
      sx={{
        backgroundColor: colors.white,
      }}
    >
      <CountdownTimer />
      <SidebarMenu />
      <Box
        flexGrow={1}
        sx={{
          width: isCollapsed ? "calc(100% - 80px)" : "calc(100% - 260px)",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

AuthenticatedContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default observer(AuthenticatedContent);
