import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CustomButton from "components/buttons/CustomButton";
import SpinningCircle from "components/spinners/SpinningCircle";
import CustomTable from "components/tables/CustomTable";
import { useMemo, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router";
import colors from "config/theme/colors";
import { useGetAllMaintenances } from "hooks/queries/useMaintenances";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import PropTypes from "prop-types";
import FilterButton from "components/buttons/FilterButton";
import { useGetAllMaintenanceTypes } from "hooks/queries/useMaintenanceType";
import { useGetAssetById } from "hooks/queries/useAssets";
import { useGetEquipmentById } from "hooks/queries/useEquipment";
import usePagination from "hooks/usePagination";
import dayjs from "dayjs";
import StatusLabel from "components/labels/StatusLabel";
import enums from "config/enums";
import EquipmentMaintenancesOverview from "./EquipmentMaintenancesOverview";
import { useGetAllEquipmentTypes } from "hooks/queries/useEquipmentType";
import { useGetAllCompanies } from "hooks/queries/useCompanies";
import { Button } from "@mui/material";
import OpenInBrowserOutlinedIcon from "@mui/icons-material/OpenInBrowserOutlined";

const MaintenanceOverview = ({ facilityType = null }) => {
  const navigate = useNavigate();
  const {
    page,
    setPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination();
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedMaintenanceTypeIds, setSelectedMaintenanceTypeIds] = useState(
    []
  );
  const { id: assetId, equipmentId } = useParams();
  const equipmentIds = equipmentId ? [equipmentId] : null;
  const {
    data: selectedAsset,
    isLoading: isSelectedAssetLoading,
    error: selectedAssetError,
  } = useGetAssetById(assetId, {
    enabled: facilityType === "asset",
  });
  const {
    data: selectedEquipment,
    isLoading: isSelectedEquipmentLoading,
    error: selectedEquipmentError,
  } = useGetEquipmentById(equipmentId, {
    enabled: facilityType === "equipment",
  });
  const {
    data: maintenanceTypes,
    isLoading: isMaintenanceTypesLoading,
    error: errorMaintenanceTypes,
  } = useGetAllMaintenanceTypes(
    {
      assetCategoryId:
        facilityType === "asset" ? selectedAsset?.assetCategory?.id : null,
      equipmentTypeIds:
        facilityType === "equipment"
          ? [
              selectedEquipment?.equipmentClass?.equipmentGroup?.equipmentType
                ?.id,
            ]
          : null,
    },
    {
      select: (data) => data.result?.items || [],
    }
  );
  const {
    data: equipmentTypes,
    isLoading: isEquipmentTypesLoading,
    error: errorEquipmentTypes,
  } = useGetAllEquipmentTypes(
    {
      assetCategoryId:
        facilityType === "asset" ? selectedAsset?.assetCategory?.id : null,
    },
    {
      enabled: facilityType === "asset",
      select: (data) => data.result?.items || [],
    }
  );
  const {
    data: equipmentMaintenanceTypes,
    isLoading: isEquipmentMaintenanceTypesLoading,
    error: errorEquipmentMaintenanceTypes,
  } = useGetAllMaintenanceTypes(
    {
      equipmentTypeIds: equipmentTypes?.map((type) => type.id),
    },
    {
      enabled: facilityType === "asset",
      select: (data) => data.result?.items || [],
    }
  );
  const {
    data: maintenances,
    isLoading: isMaintenancesLoading,
    error: errorMaintenances,
    refetch: refetchMaintenances,
  } = useGetAllMaintenances({
    pageNumber: page,
    pageSize: rowsPerPage,
    assetId: facilityType === "asset" ? assetId : null,
    equipmentIds: facilityType === "equipment" ? equipmentIds : null,
    maintenanceTypeIds: selectedMaintenanceTypeIds,
  });
  const {
    data: companies,
    isLoading: isCompaniesLoading,
    error: errorCompanies,
  } = useGetAllCompanies({}, { select: (data) => data.result?.items || [] });

  const filterGroups = [
    {
      title: "Τύπος Συντήρησης",
      filters: maintenanceTypes?.map((type) => ({
        id: type.id,
        label: type.name,
        type: "checkbox",
      })),
    },
  ];

  const handleSubmitFilters = useCallback(
    (filters) => {
      const maintenanceTypeIds = filters["Τύπος Συντήρησης"] || [];
      setSelectedFilters(filters);
      setSelectedMaintenanceTypeIds(maintenanceTypeIds);
      if (filters) {
        setPage(1);
      }

      if (maintenanceTypeIds.length === 0) {
        refetchMaintenances();
      }
    },
    [
      setSelectedFilters,
      setSelectedMaintenanceTypeIds,
      refetchMaintenances,
      setPage,
    ]
  );

  const tableHeaders = useMemo(
    () => [
      {
        id: "name",
        label: "Όνομα",
        isSortable: false,
      },
      {
        id: "contract",
        label: "Σύμβαση",
        isSortable: false,
      },
      {
        id: "company",
        label: "Οργανισμός",
        isSortable: false,
      },
      {
        id: "frequency",
        label: "Συχνότητα (μέρες)",
        isSortable: false,
      },
      {
        id: "lastDate",
        label: "Ημ/νία Τελευταίας Συντήρησης",
        isSortable: false,
      },
      {
        id: "nextDate",
        label: "Ημ/νία Επόμενης Συντήρησης",
        isSortable: false,
      },
      {
        id: "maintenanceType",
        label: "Τύπος",
        isSortable: false,
      },
      {
        id: "status",
        label: "Κατάσταση",
        isSortable: false,
      },
    ],
    []
  );

  if (
    isMaintenancesLoading ||
    isMaintenanceTypesLoading ||
    isSelectedAssetLoading ||
    isSelectedEquipmentLoading ||
    isEquipmentTypesLoading ||
    isEquipmentMaintenanceTypesLoading ||
    isCompaniesLoading
  ) {
    return <SpinningCircle />;
  }

  if (
    errorMaintenances ||
    errorMaintenanceTypes ||
    selectedAssetError ||
    selectedEquipmentError ||
    errorEquipmentTypes ||
    errorEquipmentMaintenanceTypes ||
    errorCompanies
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>
          Error:{" "}
          {errorMaintenances?.message ||
            errorMaintenanceTypes?.message ||
            selectedAssetError?.message ||
            selectedEquipmentError?.message ||
            errorEquipmentTypes?.message ||
            errorEquipmentMaintenanceTypes?.message ||
            errorCompanies?.message}
        </p>
      </Box>
    );
  }

  const maintenancesCount = maintenances?.totalCount;
  const maintenancesList = maintenances?.items?.map((maintenance) => ({
    ...maintenance,
    name: maintenance?.name || "-",
    frequency: maintenance?.frequency || "-",
    maintenanceType: maintenance?.maintenanceType?.name || "-",
    contract: maintenance?.contract?.name || "-",
    company: maintenance?.contract?.externalCompanyId ? (
      <Button
        onClick={(e) => {
          e.stopPropagation();
          navigate(`/companies/${maintenance?.contract?.externalCompanyId}`);
        }}
        sx={{
          textTransform: "none",
          fontSize: 14,
          fontWeight: 700,
          color: colors.text,
          backgroundColor: "transparent",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
      >
        <Box
          component="span"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={1}
        >
          {
            companies?.find(
              (company) =>
                company.id === maintenance?.contract?.externalCompanyId
            )?.name
          }
          <OpenInBrowserOutlinedIcon fontSize={"small"} />
        </Box>
        {}
      </Button>
    ) : (
      "-"
    ),
    lastDate: maintenance?.lastDate
      ? dayjs(maintenance?.lastDate).format("DD/MM/YYYY")
      : "-",
    nextDate:
      maintenance?.manualNextMaintenanceDate || maintenance?.nextMaintenanceDate
        ? dayjs(
            maintenance?.manualNextMaintenanceDate ||
              maintenance?.nextMaintenanceDate
          ).format("DD/MM/YYYY")
        : "-",
    status: maintenance?.status ? (
      <StatusLabel
        statusValue={maintenance?.status}
        statusText={enums.MaintenanceStatus[maintenance?.status]}
      />
    ) : (
      "-"
    ),
  }));

  const handleItemClick = (item) => {
    navigate(`${item.id}`);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={4}
      sx={{
        height: "100%",
      }}
    >
      <Box
        display="flex"
        flexDirection={{
          xs: "column",
          sm: "row",
        }}
        justifyContent={{
          xs: "space-between",
        }}
        flexGrow={1}
        flexWrap={{
          xs: "wrap",
          md: "nowrap",
        }}
        width={"100%"}
        gap={2}
      >
        <Box
          display="flex"
          gap={2}
          flexWrap={{
            xs: "wrap",
            sm: "nowrap",
          }}
          sx={{
            overflowX: "auto",
          }}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flexGrow={1}
        gap={2}
        width={"100%"}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={4}
          flexWrap={"wrap"}
        >
          <Typography variant="h3" color="primary">
            {facilityType === "asset" ? "Συντηρήσεις Ακινήτου " : "Συντηρήσεις"}{" "}
            ({maintenancesCount})
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              flexGrow: 1,
              gap: 2,
            }}
          >
            <FilterButton
              selectedFilters={selectedFilters}
              filterGroups={filterGroups}
              onApplyFilters={handleSubmitFilters}
            />
            <CustomButton
              icon={<AddIcon fill={colors.primary} width={20} height={20} />}
              title="Προσθήκη"
              orientation="row"
              fontSize={13}
              fontWeight={700}
              sx={{
                minHeight: "33px",
                maxHeight: "33px",
                borderRadius: 3,
                paddingY: 2.3,
              }}
              onClick={() => {
                navigate("new");
              }}
            />
          </Box>
        </Box>

        {maintenancesList.length > 0 &&
        tableHeaders &&
        maintenancesCount > 0 ? (
          <CustomTable
            columns={tableHeaders}
            data={maintenancesList}
            fadeEffect
            onItemClick={handleItemClick}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalResults={maintenancesCount}
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
            }}
          >
            <Typography variant="h4">Δεν υπάρχουν αποτελέσματα</Typography>
          </Box>
        )}
      </Box>

      {facilityType === "asset" && (
        <EquipmentMaintenancesOverview
          assetId={parseInt(assetId)}
          maintenanceTypes={equipmentMaintenanceTypes}
          equipmentTypes={equipmentTypes}
        />
      )}
    </Box>
  );
};

MaintenanceOverview.propTypes = {
  facilityType: PropTypes.oneOf(["equipment", "asset"]).isRequired,
};

export default MaintenanceOverview;
