import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SaveButton from "components/buttons/SaveButton";
import CustomTable from "components/tables/CustomTable";
import DateField from "components/inputs/DateField";
import DropdownField from "components/inputs/DropdownField";
import ReportsAccordion from "components/accordions/ReportsAccordion";
import usePagination from "hooks/usePagination";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import colors from "config/theme/colors";
import CopyButton from "components/buttons/CopyButton";
import {
  useGetTasks,
  useGetTasksPerMaintenanceType,
} from "hooks/queries/useTaskReports";
import dayjs from "dayjs";
import TaskReportsExportButtons from "./TaskReportsExportButtons";
import { formatDateTime } from "utils/formatDateTime";
import DatePeriodSelector from "components/inputs/DatePeriodSelector";

const availableTaskReportTypeOptions = [
  { label: "Εκκρεμείς Εργασίες", value: "PendingTasks" },
  { label: "Ολοκληρωμένες Εργασίες", value: "CompletedTasks" },
  { label: "Εργασίες Ανά Τύπο Συντήρησης", value: "TasksByMaintenanceType" },
];

const columns = {
  PendingTasks: [
    { id: "taskDescription", label: "Περιγραφή Εργασίας", isSortable: false },
    { id: "assetName", label: "Ακίνητο", isSortable: false },
    { id: "kaek", label: "ΚΑΕΚ", isSortable: false },
    { id: "equipmentName", label: "Εξοπλισμός", isSortable: false },
    {
      id: "date",
      label: "Αναμενόμενη Ημερομηνία Ολοκλήρωσης",
      isSortable: false,
    },
  ],
  CompletedTasks: [
    { id: "taskDescription", label: "Περιγραφή Εργασίας", isSortable: false },
    { id: "assetName", label: "Ακίνητο", isSortable: false },
    { id: "kaek", label: "ΚΑΕΚ", isSortable: false },
    { id: "equipmentName", label: "Εξοπλισμός", isSortable: false },
    {
      id: "date",
      label: "Ημερομηνία Ολοκλήρωσης",
      isSortable: false,
    },
  ],
  TasksByMaintenanceType: [
    { id: "maintenanceType", label: "Τύπος Συντήρησης", isSortable: false },
    {
      id: "numberOfTasksCompleted",
      label: "Αριθμός Ολοκληρωμένων Εργασιών",
      isSortable: false,
    },
  ],
};

const getValidationSchema = (reportType) => {
  if (
    reportType === "PendingTasks" ||
    reportType === "CompletedTasks" ||
    reportType === "TasksByMaintenanceType"
  ) {
    return yup.object().shape({
      dateFrom: yup.date().required("Ημερομηνία από είναι υποχρεωτική"),
      dateTo: yup.date().required("Ημερομηνία έως είναι υποχρεωτική"),
    });
  } else {
    return yup.object().shape({});
  }
};

const renderKaek = (item) => {
  return (
    <Box
      px={2}
      borderRadius={2}
      bgcolor={colors.greyBackground}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography fontSize={12} fontWeight={700} color={colors.grey}>
        {item.kaek}
      </Typography>
      <CopyButton textToCopy={item.kaek} />
    </Box>
  );
};

const TaskReports = () => {
  const [reportType, setReportType] = useState("");
  const [data, setData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    page,
    rowsPerPage,
    setPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(getValidationSchema(reportType)),
  });

  const dateFrom = watch("dateFrom");
  const dateTo = watch("dateTo");

  const handleSelectPeriod = (startDate, endDate) => {
    setValue("dateFrom", startDate);
    setValue("dateTo", endDate);
  };

  const fetchedData = {
    PendingTasks: useGetTasks(
      {
        status: "Εκκρεμεί",
        dateFrom: dayjs(dateFrom).utc(true).format(),
        dateTo: dayjs(dateTo).utc(true).format(),
        pageNumber: page,
        pageSize: rowsPerPage,
      },
      { enabled: reportType === "PendingTasks" && isSubmitting }
    ),
    CompletedTasks: useGetTasks(
      {
        status: "Ολοκληρωμένο",
        dateFrom: dayjs(dateFrom).utc(true).format(),
        dateTo: dayjs(dateTo).utc(true).format(),
        pageNumber: page,
        pageSize: rowsPerPage,
      },
      { enabled: reportType === "CompletedTasks" && isSubmitting }
    ),
    TasksByMaintenanceType: useGetTasksPerMaintenanceType(
      {
        dateFrom: dayjs(dateFrom).utc(true).format(),
        dateTo: dayjs(dateTo).utc(true).format(),
        pageNumber: page,
        pageSize: rowsPerPage,
      },
      { enabled: reportType === "TasksByMaintenanceType" && isSubmitting }
    ),
  };

  useEffect(() => {
    if (isSubmitting) {
      const activeQuery = fetchedData[reportType];
      setData(activeQuery.data || {});
    }
  }, [fetchedData, reportType, isSubmitting]);

  const isLoading = fetchedData[reportType]?.isLoading;

  const formattedData = useMemo(
    () =>
      data?.items?.map((item, i) => {
        switch (reportType) {
          case "PendingTasks":
          case "CompletedTasks":
            return {
              id: `${item.date}-${item.kaek}-${i}`,
              taskDescription: item.taskDescription || "-",
              assetName: item.assetName || "-",
              kaek: item.kaek ? renderKaek(item) : "-",
              equipmentName: item.equipmentName || "-",
              date: formatDateTime(item.date, true),
            };
          case "TasksByMaintenanceType":
            return {
              id: `${item.maintenanceType}-${i}`,
              maintenanceType: item.maintenanceType || "-",
              numberOfTasksCompleted: item.numberOfTasksCompleted || "-",
            };
          default:
            return item;
        }
      }),
    [data, reportType]
  );

  const totalResults = data?.totalCount ?? null;
  const taskReportTypeOptions = availableTaskReportTypeOptions.map(
    (option) => option.label
  );

  const handleOnFilterChange = () => {
    setIsSubmitting(false);
    setPage(1);
  };

  const handleReportTypeChange = (event, value) => {
    const selectedReport = availableTaskReportTypeOptions.find(
      (option) => option.label === value
    )?.value;

    setReportType(selectedReport || "");
    reset();
    handleOnFilterChange();
  };

  const onSubmit = () => {
    setIsSubmitting(true);
  };

  const getMinMaxDates = useCallback((reportType) => {
    const currentDate = dayjs().format("YYYY-MM-DD");
    const pastDate = dayjs().subtract(1, "year").format("YYYY-MM-DD");

    switch (reportType) {
      case "PendingTasks":
        return { min: null, max: null };
      case "CompletedTasks":
        return { min: pastDate, max: currentDate };
      case "TasksByMaintenanceType":
        return { min: pastDate, max: currentDate };
      default:
        return { min: null, max: null };
    }
  }, []);

  const { min: dateFromMin, max: dateFromMax } = getMinMaxDates(reportType);
  const { min: dateToMin, max: dateToMax } = getMinMaxDates(reportType);

  return (
    <Box sx={{ marginTop: 4 }}>
      <Typography variant="h4" color="primary">
        Αναφορές Εργασιών
      </Typography>

      <Box sx={{ marginTop: 3 }}>
        <DropdownField
          label="Επιλογή Κατηγορίας Αναφοράς"
          value={
            availableTaskReportTypeOptions.find(
              (option) => option.value === reportType
            )?.label || ""
          }
          onChange={handleReportTypeChange}
          options={taskReportTypeOptions}
          required
        />

        <Box sx={{ marginTop: 3 }}>
          {availableTaskReportTypeOptions
            .map((option) => option.value)
            .includes(reportType) && (
            <ReportsAccordion
              sx={{
                marginTop: 2,
                width: "100%",
                padding: 1.7,
                borderRadius: 2,
                boxShadow: 3,
                border: "1px solid",
                borderColor: "divider",
              }}
              title={`Φόρμα Αναφοράς ( ${
                availableTaskReportTypeOptions.find(
                  (option) => option.value === reportType
                )?.label
              } )`}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ marginBottom: 4 }}>
                  <DatePeriodSelector
                    onSelectPeriod={handleSelectPeriod}
                    includePast={reportType !== "PendingTasks"}
                    includeFuture={
                      reportType !== "TasksByMaintenanceType" &&
                      reportType !== "CompletedTasks"
                    }
                  />
                </Box>
                <Box display="flex" gap={2} flexWrap="wrap">
                  <DateField
                    label="Ημερομηνία Από"
                    name="dateFrom"
                    control={control}
                    min={dateFromMin}
                    max={dateFromMax}
                    required
                    onChange={() => handleOnFilterChange()}
                  />
                  <DateField
                    label="Ημερομηνία Εώς"
                    name="dateTo"
                    control={control}
                    min={dateToMin}
                    max={dateToMax}
                    required
                    onChange={() => handleOnFilterChange()}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 4,
                      marginLeft: 4,
                    }}
                  >
                    {Object.keys(errors).length > 0 && (
                      <Typography color="error" fontSize={14}>
                        Παρακαλώ ελέγξτε ότι όλα τα πεδία έχουν συμπληρωθεί
                        σωστά
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box sx={{ marginTop: 3 }}>
                  <SaveButton text="Δημιουργία" isLoading={isLoading} />
                </Box>
              </form>
            </ReportsAccordion>
          )}

          <Box sx={{ marginTop: 3.5, display: "flex", alignItems: "center" }}>
            {totalResults > 0 && isSubmitting && (
              <>
                <Typography variant="h3" color="primary">
                  ({totalResults}) Αποτελέσματα
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "flex-end",
                  }}
                >
                  <TaskReportsExportButtons
                    reportType={reportType}
                    filters={{
                      dateFrom: dayjs(dateFrom).utc(true).format(),
                      dateTo: dayjs(dateTo).utc(true).format(),
                      status:
                        reportType === "PendingTasks"
                          ? "Εκκρεμεί"
                          : "Ολοκληρωμένο",
                    }}
                  />
                </Box>
              </>
            )}
          </Box>

          {totalResults > 0 && isSubmitting && (
            <Box
              sx={{
                marginTop: 3,
                marginBottom: 5,
                width: "100%",
                padding: 1.7,
                borderRadius: 2,
                boxShadow: 3,
                border: "1px solid",
                borderColor: "divider",
              }}
            >
              <CustomTable
                columns={columns[reportType]}
                data={formattedData}
                page={page - 1}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                totalResults={totalResults}
                disableClick
                maxHeight={650}
                textWeight={500}
              />
            </Box>
          )}

          {totalResults === 0 && isSubmitting && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "100%",
                marginTop: 2,
              }}
            >
              <Typography variant="h4">Δεν υπάρχουν αναφορές</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TaskReports;
