import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MaintenanceViewEditForm from "./MaintenanceViewEditForm";
import { useNavigate, useOutletContext, useParams } from "react-router";
import { useState } from "react";
import SpinningCircle from "components/spinners/SpinningCircle";
import EditButton from "components/buttons/EditButton";
import {
  useBypassNextMaintenance,
  useDeleteMaintenance,
  useGetMaintenanceById,
  useUpdateMaintenance,
} from "hooks/queries/useMaintenances";
import { useAlert } from "hooks/useAlert";
import ReturnButton from "components/buttons/ReturnButton";
import colors from "config/theme/colors";
import { useUserProfile } from "hooks/queries/useAuth";
import { useGetAllMaintenanceTypes } from "hooks/queries/useMaintenanceType";
import MaintenanceHistoryOverview from "pages/maintenanceHistory/Overview/MaintenanceHistoryOverview";
import PropTypes from "prop-types";
import { useDeleteMultipleFiles, useUploadFiles } from "hooks/queries/useFile";
import EditCalendarOutlinedIcon from "@mui/icons-material/EditCalendarOutlined";
import { IconButton, Tooltip } from "@mui/material";
import BypassNextMaintenanceModal from "./BypassNextMaintenanceModal";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const MaintenanceViewEdit = ({ facilityType = null }) => {
  const navigate = useNavigate();
  const { maintenanceId } = useParams();
  const { selectedAsset, selectedEquipment } = useOutletContext();
  const { showError, showSuccess, showWarning } = useAlert();
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isHistoryVisible, setIsHistoryVisible] = useState(true);
  const [isBypassModalOpen, setIsBypassModalOpen] = useState(false);
  const {
    data: selectedMaintenance,
    isLoading: isMaintenanceLoading,
    isError: isMaintenanceError,
    error: maintenanceError,
  } = useGetMaintenanceById(maintenanceId, {
    select: (data) => data?.result,
    enabled: !!maintenanceId && !isDeleted,
  });
  const {
    data: userProfile,
    isLoading: isProfileLoading,
    isError: isProfileError,
    error: profileError,
  } = useUserProfile();
  const {
    data: maintenancesTypes,
    isLoading: isMaintenancesTypesLoading,
    isError: isMaintenancesTypesError,
    error: errorMaintenancesTypes,
  } = useGetAllMaintenanceTypes({
    assetCategoryId:
      facilityType === "asset" ? selectedAsset?.assetCategory?.id : null,
    equipmentTypeIds:
      facilityType === "equipment"
        ? [selectedEquipment?.equipmentClass?.equipmentGroup?.equipmentType?.id]
        : null,
  });

  const { mutate: updateMaintenance } = useUpdateMaintenance({
    id: selectedMaintenance?.id,
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        setIsEditing(false);
        showSuccess("Η συντήρηση ενημερώθηκε με επιτυχία");
      },
    },
  });
  const { mutate: deleteMaintenace } = useDeleteMaintenance({
    id: selectedMaintenance?.id,
    config: {
      onError: (error) => {
        showError(error.message);
        setIsDeleted(false);
      },
      onSuccess: () => {
        showSuccess("Η συντήρηση διαγράφηκε με επιτυχία");
        navigate(-1);
      },
    },
  });
  const { mutate: uploadFiles } = useUploadFiles({
    fileableId: selectedMaintenance?.id,
    fileableType: "Maintenance",
  });
  const { mutate: deleteMultipleFiles } = useDeleteMultipleFiles();

  const { mutate: bypassNextMaintenance } = useBypassNextMaintenance({
    id: selectedMaintenance?.id,
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        showSuccess("Η συντήρηση παρακάμφθηκε με επιτυχία");
      },
    },
  });

  if (isMaintenanceLoading || isProfileLoading || isMaintenancesTypesLoading) {
    return <SpinningCircle />;
  }

  if (isMaintenanceError || isProfileError || isMaintenancesTypesError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>
          Error:
          {maintenanceError?.message ||
            profileError?.message ||
            errorMaintenancesTypes?.message}
        </p>
      </Box>
    );
  }

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleUpdateWhenDeleteOnly = (payload, deletedFiles) => {
    deleteMultipleFiles(
      deletedFiles.map((file) => file.id),
      {
        onSuccess: () => {
          updateMaintenance(payload);
        },
        onError: (error) => {
          showWarning(
            "Υπήρξε πρόβλημα με τη διαγραφή των αρχείων. Παρακαλώ δοκιμάστε ξανά την ενημέρωση των αρχείων"
          );
          showError(error.message);
        },
      }
    );
  };

  const handleUpdateWhenUploadOnly = (payload, newFiles) => {
    uploadFiles(
      { files: newFiles },
      {
        onSuccess: () => {
          updateMaintenance(payload);
        },
        onError: (error) => {
          showWarning(
            "Υπήρξε πρόβλημα με το ανέβασμα των αρχείων. Παρακαλώ δοκιμάστε ξανά την ενημέρωση των αρχείων"
          );
          showError(error.message);
        },
      }
    );
  };

  const handleUpdateWhenDeleteAndUpload = (payload, deletedFiles, newFiles) => {
    deleteMultipleFiles(
      deletedFiles.map((file) => file.id),
      {
        onSuccess: () => {
          uploadFiles(
            { files: newFiles },
            {
              onSuccess: () => {
                updateMaintenance(payload);
              },
              onError: (error) => {
                showWarning(
                  "Υπήρξε πρόβλημα με το ανέβασμα των αρχείων. Παρακαλώ δοκιμάστε ξανά την ενημέρωση των αρχείων"
                );
                showError(error.message);
              },
            }
          );
        },
        onError: (error) => {
          showWarning(
            "Υπήρξε πρόβλημα με τη διαγραφή των αρχείων. Παρακαλώ δοκιμάστε ξανά την ενημέρωση των αρχείων"
          );
          showError(error.message);
        },
      }
    );
  };

  const handleSaveClick = (data) => {
    const payload = {
      name: data.name || "",
      description: data.description || "",
      frequency: data.frequency || "",
      notes: data.notes || "",
      userId: userProfile?.userDto?.id || "",
      maintenanceTypeId:
        maintenancesTypes?.items?.find(
          (type) => type.name === data.maintenanceTypeId
        )?.id || null,
      contractId: data.contractId?.id || null,
      assetId: facilityType === "asset" ? selectedAsset.id : null,
      equipmentId: facilityType === "equipment" ? selectedEquipment.id : null,
      status: data.status?.id || "",
    };

    const newFiles = data.files?.filter((file) =>
      selectedMaintenance.files.every((attachment) => attachment.id !== file.id)
    );

    const deletedFiles = selectedMaintenance.files?.filter(
      (attachment) => !data.files?.some((file) => file.id === attachment.id)
    );

    if (deletedFiles.length === 0 && newFiles.length === 0) {
      updateMaintenance(payload);
    } else if (deletedFiles.length > 0 && newFiles.length === 0) {
      handleUpdateWhenDeleteOnly(payload, deletedFiles);
    } else if (deletedFiles.length === 0 && newFiles.length > 0) {
      handleUpdateWhenUploadOnly(payload, newFiles);
    } else {
      handleUpdateWhenDeleteAndUpload(payload, deletedFiles, newFiles);
    }
  };

  const handleDeleteClick = () => {
    setIsEditing(false);
    setIsDeleted(true);
    deleteMaintenace();
  };

  const handleBypassNextMaintenance = () => {
    setIsBypassModalOpen(true);
  };

  const toggleHistoryVisibility = () => {
    setIsHistoryVisible((prev) => !prev);
  };

  return (
    selectedMaintenance && (
      <>
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent="flex-start"
          gap={1}
        >
          <Typography color={colors.primary} fontWeight={700} fontSize={24}>
            Διαχείριση Συντήρησης
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{
            height: "100%",
          }}
          mt={4}
        >
          <Box
            mb={2}
            display="flex"
            gap={2}
            justifyContent="space-between"
            alignItems={{
              xs: "flex-start",
              sm: "center",
            }}
            flexWrap={"wrap"}
          >
            <Box
              display="flex"
              gap={2}
              alignItems="center"
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
            >
              <ReturnButton />
              <Typography fontSize={20} fontWeight={600} color={colors.primary}>
                {selectedMaintenance.name}
              </Typography>
            </Box>
            <Box
              display={"flex"}
              gap={2}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <Tooltip
                title="Χεροκίνητη επιλογή επόμενης συντήρησης"
                placement="left"
              >
                <IconButton onClick={handleBypassNextMaintenance}>
                  <EditCalendarOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Box
                display="flex"
                gap={2}
                sx={{
                  width: {
                    xs: "100%",
                    sm: "auto",
                  },
                }}
              >
                <EditButton onClick={handleEditClick} disabled={isEditing} />
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            gap={2}
            justifyContent="space-between"
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                width: `${isHistoryVisible ? "50%" : "100%"}`,
              }}
            >
              <MaintenanceViewEditForm
                selectedMaintenance={selectedMaintenance}
                isEditing={isEditing}
                onSave={handleSaveClick}
                onCancel={handleCancelClick}
                onDelete={handleDeleteClick}
                selectedAsset={facilityType === "asset" ? selectedAsset : null}
                selectedEquipment={
                  facilityType === "equipment" ? selectedEquipment : null
                }
                facilityType={facilityType}
              />
            </Box>
            <Box
              sx={{
                borderLeft: `solid ${isHistoryVisible ? "2px" : "0px"}`,
                borderColor: colors.lightGrey,
                opacity: isHistoryVisible ? "0.2" : "0.5",
                marginLeft: 2,
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton onClick={toggleHistoryVisibility} size="small">
                {isHistoryVisible ? (
                  <ArrowForwardIosIcon />
                ) : (
                  <ArrowBackIosNewIcon />
                )}
              </IconButton>
            </Box>
            {isHistoryVisible && (
              <Box
                sx={{
                  width: "50%",
                  transition: "width 0.3s ease",
                }}
              >
                <MaintenanceHistoryOverview maintenanceId={maintenanceId} />
              </Box>
            )}
          </Box>
        </Box>

        <BypassNextMaintenanceModal
          isOpen={isBypassModalOpen}
          onClose={() => setIsBypassModalOpen(false)}
          onBypass={bypassNextMaintenance}
        />
      </>
    )
  );
};

MaintenanceViewEdit.propTypes = {
  facilityType: PropTypes.oneOf(["equipment", "asset"]).isRequired,
};

export default MaintenanceViewEdit;
