import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SaveButton from "components/buttons/SaveButton";
import CustomTable from "components/tables/CustomTable";
import DateField from "components/inputs/DateField";
import DropdownField from "components/inputs/DropdownField";
import ReportsAccordion from "components/accordions/ReportsAccordion";
import usePagination from "hooks/usePagination";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useGetReportTicketsPerAsset,
  useGetReportTickets,
} from "hooks/queries/useTicketReports";
import colors from "config/theme/colors";
import CopyButton from "components/buttons/CopyButton";
import dayjs from "dayjs";
import { formatDateTime } from "utils/formatDateTime";
import TicketReportsExportButtons from "./TicketReportsExportButtons";
import DatePeriodSelector from "components/inputs/DatePeriodSelector";

const availableTicketReportTypeOptions = [
  { label: "Εκκρεμή Τεχνικά Αιτήματα", value: "PendingTickets" },
  { label: "Ολοκληρωμένα Τεχνικά Αιτήματα", value: "CompletedTickets" },
  {
    label: "Τεχνικά Αιτήματα Ανά Ακίνητο",
    value: "TicketsPerMaintenanceType",
  },
];

const columns = {
  PendingTickets: [
    {
      id: "ticketName",
      label: "Αίτημα",
      isSortable: false,
    },
    { id: "assetName", label: "Ακίνητο", isSortable: false },
    { id: "kaek", label: "ΚΑΕΚ", isSortable: false },
    { id: "equipmentName", label: "Εξοπλισμός", isSortable: false },
    { id: "creationDate", label: "Ημερομηνία Δημιουργίας", isSortable: false },
  ],
  CompletedTickets: [
    {
      id: "ticketName",
      label: "Αίτημα",
      isSortable: false,
    },
    { id: "assetName", label: "Ακίνητο", isSortable: false },
    { id: "kaek", label: "ΚΑΕΚ", isSortable: false },
    { id: "equipmentName", label: "Εξοπλισμός", isSortable: false },
    { id: "creationDate", label: "Ημερομηνία Δημιουργίας", isSortable: false },
    {
      id: "completionDate",
      label: "Ημερομηνία Ολοκλήρωσης",
      isSortable: false,
    },
  ],
  TicketsPerMaintenanceType: [
    { id: "assetName", label: "Ακίνητο", isSortable: false },
    { id: "kaek", label: "ΚΑΕΚ", isSortable: false },
    {
      id: "equipmentName",
      label: "Eξοπλισμός",
      isSortable: false,
    },
    {
      id: "ticketsCompleted",
      label: "Ολοκληρωμένα Αιτήματα",
      isSortable: false,
    },
    {
      id: "responseTime",
      label: "Μέσος Χρόνος Απόκρισης",
      isSortable: false,
    },
  ],
};

const getValidationSchema = (reportType) => {
  if (
    reportType === "PendingTickets" ||
    reportType === "CompletedTickets" ||
    reportType === "TicketsPerMaintenanceType"
  ) {
    return yup.object().shape({
      dateFrom: yup.date().required("Ημερομηνία από είναι υποχρεωτική"),
      dateTo: yup.date().required("Ημερομηνία έως είναι υποχρεωτική"),
    });
  } else {
    return yup.object().shape({});
  }
};

const renderKaek = (item) => {
  return (
    <Box
      px={2}
      borderRadius={2}
      bgcolor={colors.greyBackground}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography fontSize={12} fontWeight={700} color={colors.grey}>
        {item.kaek}
      </Typography>
      <CopyButton textToCopy={item.kaek} />
    </Box>
  );
};

const TicketReports = () => {
  const [reportType, setReportType] = useState("");
  const [data, setData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    page,
    rowsPerPage,
    setPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(getValidationSchema(reportType)),
  });

  const dateFrom = watch("dateFrom");
  const dateTo = watch("dateTo");

  const handleSelectPeriod = (startDate, endDate) => {
    setValue("dateFrom", startDate);
    setValue("dateTo", endDate);
  };

  const fetchedData = {
    PendingTickets: useGetReportTickets(
      {
        status: "Pending",
        dateFrom: dayjs(dateFrom).utc(true).format(),
        dateTo: dayjs(dateTo).utc(true).format(),
        pageNumber: page,
        pageSize: rowsPerPage,
      },
      { enabled: reportType === "PendingTickets" && isSubmitting }
    ),
    CompletedTickets: useGetReportTickets(
      {
        status: "Completed",
        dateFrom: dayjs(dateFrom).utc(true).format(),
        dateTo: dayjs(dateTo).utc(true).format(),
        pageNumber: page,
        pageSize: rowsPerPage,
      },
      { enabled: reportType === "CompletedTickets" && isSubmitting }
    ),
    TicketsPerMaintenanceType: useGetReportTicketsPerAsset(
      {
        dateFrom: dayjs(dateFrom).utc(true).format(),
        dateTo: dayjs(dateTo).utc(true).format(),
        pageNumber: page,
        pageSize: rowsPerPage,
      },
      { enabled: reportType === "TicketsPerMaintenanceType" && isSubmitting }
    ),
  };

  useEffect(() => {
    if (isSubmitting) {
      const activeQuery = fetchedData[reportType];
      setData(activeQuery.data || {});
    }
  }, [fetchedData, reportType, isSubmitting]);

  const isLoading = fetchedData[reportType]?.isLoading;

  const formattedData = useMemo(
    () =>
      data?.items?.map((item, i) => {
        switch (reportType) {
          case "PendingTickets":
            return {
              id: `${item.creationDate}-${item.kaek}-${i}`,
              ticketName: item.ticketName || "-",
              assetName: item.assetName || "-",
              kaek: item.kaek ? renderKaek(item) : "-",
              equipmentName: item.equipmentName || "-",
              creationDate: formatDateTime(item.creationDate, true),
            };
          case "CompletedTickets":
            return {
              id: `${item.creationDate}-${item.kaek}-${i}`,
              ticketName: item.ticketName || "-",
              assetName: item.assetName || "-",
              kaek: item.kaek ? renderKaek(item) : "-",
              equipmentName: item.equipmentName || "-",
              creationDate: formatDateTime(item.creationDate, true),
              completionDate: formatDateTime(item.completionDate, true),
            };
          case "TicketsPerMaintenanceType":
            return {
              id: `${item.assetName}-${item.kaek}-${i}`,
              assetName: item.assetName || "-",
              kaek: item.kaek ? renderKaek(item) : "-",
              equipmentName: item.equipmentName || "-",
              ticketsCompleted: item.ticketsCompleted || "-",
              responseTime: `${item.responseTime > 1 ? item.responseTime + " ημέρες" : item.responseTime + " ημέρα"}`,
            };
          default:
            return item;
        }
      }),
    [data, reportType]
  );

  const totalResults = data?.totalCount ?? null;
  const ticketReportTypeOptions = availableTicketReportTypeOptions.map(
    (option) => option.label
  );

  const handleOnFilterChange = () => {
    setIsSubmitting(false);
    setPage(1);
  };

  const handleReportTypeChange = (event, value) => {
    const selectedReport = availableTicketReportTypeOptions.find(
      (option) => option.label === value
    )?.value;
    setReportType(selectedReport || "");
    reset();
    handleOnFilterChange();
  };

  const onSubmit = () => {
    setIsSubmitting(true);
  };

  const getMinMaxDates = useCallback((reportType) => {
    const currentDate = dayjs().format("YYYY-MM-DD");
    const pastDate = dayjs().subtract(1, "year").format("YYYY-MM-DD");

    switch (reportType) {
      case "PendingTickets":
        return { min: null, max: null };
      case "CompletedTickets":
        return { min: pastDate, max: currentDate };
      case "TicketsPerMaintenanceType":
        return { min: pastDate, max: currentDate };
      default:
        return { min: null, max: null };
    }
  }, []);

  const { min: dateFromMin, max: dateFromMax } = getMinMaxDates(reportType);
  const { min: dateToMin, max: dateToMax } = getMinMaxDates(reportType);

  return (
    <Box sx={{ marginTop: 4 }}>
      <Typography variant="h4" color="primary">
        Αναφορές Τεχνικών Αιτημάτων
      </Typography>
      <Box sx={{ marginTop: 3 }}>
        <DropdownField
          label="Επιλογή Κατηγορίας Αναφοράς"
          value={
            availableTicketReportTypeOptions.find(
              (option) => option.value === reportType
            )?.label || ""
          }
          onChange={handleReportTypeChange}
          options={ticketReportTypeOptions}
          required
        />
        <Box sx={{ marginTop: 3 }}>
          {availableTicketReportTypeOptions
            .map((option) => option.value)
            .includes(reportType) && (
            <ReportsAccordion
              sx={{
                marginTop: 2,
                width: "100%",
                padding: 1.7,
                borderRadius: 2,
                boxShadow: 3,
                border: "1px solid",
                borderColor: "divider",
              }}
              title={`Φόρμα Αναφοράς ( ${
                availableTicketReportTypeOptions.find(
                  (option) => option.value === reportType
                )?.label
              } )`}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ marginBottom: 4 }}>
                  <DatePeriodSelector
                    onSelectPeriod={handleSelectPeriod}
                    includePast={reportType !== "PendingTickets"}
                    includeFuture={
                      reportType !== "TicketsPerMaintenanceType" &&
                      reportType !== "CompletedTickets"
                    }
                  />
                </Box>
                <Box display="flex" gap={2} flexWrap="wrap">
                  <DateField
                    label="Ημερομηνία Από"
                    name="dateFrom"
                    control={control}
                    min={dateFromMin}
                    max={dateFromMax}
                    required
                    onChange={() => handleOnFilterChange()}
                  />
                  <DateField
                    label="Ημερομηνία Εώς"
                    name="dateTo"
                    control={control}
                    min={dateToMin}
                    max={dateToMax}
                    required
                    onChange={() => handleOnFilterChange()}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 4,
                      marginLeft: 4,
                    }}
                  >
                    {Object.keys(errors).length > 0 && (
                      <Typography color="error" fontSize={14}>
                        Παρακαλώ ελέγξτε ότι όλα τα πεδία έχουν συμπληρωθεί
                        σωστά
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box sx={{ marginTop: 3 }}>
                  <SaveButton text="Δημιουργία" isLoading={isLoading} />
                </Box>
              </form>
            </ReportsAccordion>
          )}
          <Box sx={{ marginTop: 3.5, display: "flex", alignItems: "center" }}>
            {totalResults > 0 && isSubmitting > 0 && (
              <>
                <Typography variant="h3" color="primary">
                  ({totalResults}) Αποτελέσματα
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "flex-end",
                  }}
                >
                  <TicketReportsExportButtons
                    reportType={reportType}
                    filters={{
                      dateFrom: dayjs(dateFrom).utc(true).format(),
                      dateTo: dayjs(dateTo).utc(true).format(),
                      status:
                        reportType === "PendingTickets"
                          ? "Pending"
                          : "Completed",
                    }}
                  />
                </Box>
              </>
            )}
          </Box>
          {totalResults > 0 && isSubmitting && (
            <Box
              sx={{
                marginTop: 3,
                marginBottom: 5,
                width: "100%",
                padding: 1.7,
                borderRadius: 2,
                boxShadow: 3,
                border: "1px solid",
                borderColor: "divider",
              }}
            >
              <CustomTable
                columns={columns[reportType]}
                data={formattedData}
                page={page - 1}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                totalResults={totalResults}
                disableClick
                maxHeight={650}
                textWeight={500}
              />
            </Box>
          )}

          {totalResults === 0 && isSubmitting && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "100%",
                marginTop: 2,
              }}
            >
              <Typography variant="h4">Δεν υπάρχουν αναφορές</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TicketReports;
