import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
import PropTypes from "prop-types";
import { jwtDecode } from "jwt-decode";
import enums from "config/enums";
import { Agent } from "api/agent";

class AuthStore {
  token = null;
  refreshToken = null;
  isUserAuthenticated = false;
  rememberMe = false;

  isSuperAdmin = false;
  isAdmin = false;
  isManager = false;
  isUser = false;
  isTechnicalIssues = false;

  idleTimeout = null;
  idleTimeoutDuration = 1000 * 60 * 60; // 1 hour
  countdownValue = null;
  countdownInterval = null;

  constructor() {
    makeAutoObservable(this);
    this.setupIdleListener();
    this.initialize();
    this.updateRoles();
  }

  initialize() {
    const storedToken =
      JSON.parse(localStorage.getItem("token"))?.token ||
      JSON.parse(sessionStorage.getItem("token"))?.token;
    const storedRefreshToken =
      JSON.parse(localStorage.getItem("token"))?.refreshToken ||
      JSON.parse(sessionStorage.getItem("token"))?.refreshToken;

    if (storedToken) {
      this.token = storedToken;
      this.refreshToken = storedRefreshToken;
      this.isUserAuthenticated = true;
      this.resetIdleTimer();
    }
  }

  setToken = (value) => {
    this.token = value;
  };

  setRefreshToken = (value) => {
    this.refreshToken = value;
  };

  setIsUserAuthenticated = (value) => {
    this.isUserAuthenticated = value;
  };

  setRememberMe = (value) => {
    this.rememberMe = value;
  };

  storeToken = (data) => {
    if (this.rememberMe) {
      localStorage.setItem("token", JSON.stringify(data?.result));
    } else {
      sessionStorage.setItem("token", JSON.stringify(data?.result));
    }
    this.setToken(data?.result?.token);
    this.setRefreshToken(data?.result?.refreshToken);
    this.setIsUserAuthenticated(true);
    this.updateRoles();
    this.resetIdleTimer();
  };

  updateRoles = () => {
    if (this.token) {
      const decoded = jwtDecode(this.token);
      this.isSuperAdmin = decoded.role === enums.Roles.SuperAdmin;
      this.isAdmin = decoded.role === enums.Roles.Admin;
      this.isManager = decoded.role === enums.Roles.Manager;
      this.isUser = decoded.role === enums.Roles.User;
      this.isTechnicalIssues = decoded.role === enums.Roles.TechnicalIssues;
    } else {
      this.isSuperAdmin = false;
      this.isAdmin = false;
      this.isManager = false;
      this.isUser = false;
      this.isTechnicalIssues = false;
    }
  };

  logout = () => {
    if (this.token) {
      Agent.auth.logout(this.token, this.refreshToken).finally(() => {
        localStorage.removeItem("token");
        sessionStorage.removeItem("token");
        this.setToken(null);
        this.setIsUserAuthenticated(false);
        this.clearIdleTimer();
      });
    } else {
      this.setIsUserAuthenticated(false);
      this.clearIdleTimer();
    }
  };

  setupIdleListener = () => {
    const events = ["mousemove", "keydown", "click", "scroll"];
    const eventHandler = this.resetIdleTimer.bind(this);

    events.forEach((event) => window.addEventListener(event, eventHandler));
  };

  resetIdleTimer = () => {
    if (this.idleTimeout) clearTimeout(this.idleTimeout);
    if (this.countdownInterval) this.clearCountdown();

    this.startCountdown(this.idleTimeoutDuration / 1000);
    this.idleTimeout = setTimeout(() => {
      this.logout();
    }, this.idleTimeoutDuration);
  };

  clearIdleTimer = () => {
    if (this.idleTimeout) {
      clearTimeout(this.idleTimeout);
      this.idleTimeout = null;
    }
  };

  startCountdown = (seconds) => {
    runInAction(() => {
      this.countdownValue = seconds;
    });

    this.countdownInterval = setInterval(() => {
      runInAction(() => {
        if (this.countdownValue <= 0) {
          this.clearCountdown();
        } else {
          this.countdownValue -= 1;
        }
      });
    }, 1000);
  };

  clearCountdown = () => {
    clearInterval(this.countdownInterval);
    this.countdownValue = null;
    this.countdownInterval = null;
  };
}

export const authStore = new AuthStore();
export const AuthStoreContext = createContext(authStore);
export const AuthStoreProvider = ({ children, store }) => (
  <AuthStoreContext.Provider value={store}>
    {children}
  </AuthStoreContext.Provider>
);

AuthStoreProvider.propTypes = {
  store: PropTypes.object,
  children: PropTypes.node,
};

export const useAuthStore = () => useContext(AuthStoreContext);
