import { Box, Grid, Typography } from "@mui/material";
import CancelButton from "components/buttons/CancelButton";
import DeleteButton from "components/buttons/DeleteButton";
import SaveButton from "components/buttons/SaveButton";
import CustomTextField from "components/inputs/CustomTextField";
import DropdownField from "components/inputs/DropdownField";
import PropTypes from "prop-types";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useIsMutating } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import DateField from "components/inputs/DateField";
import equipmentResponseFields from "config/forms/equipmentResponseFields";
import { useGetAllEquipmentTypes } from "hooks/queries/useEquipmentType";
import { useGetAllEquipmentClasses } from "hooks/queries/useEquipmentClass";
import SpinningCircle from "components/spinners/SpinningCircle";
import { useOutletContext } from "react-router-dom";
import DeleteModal from "components/modals/DeleteModal";
import { useEffect, useState } from "react";
import { useGetAllEquipmentGroups } from "hooks/queries/useEquipmentGroup";
import AlfrescoFileUploader from "components/fileUploaders/AlfrescoFileUploader";
import ExitFormModal from "components/modals/ExitFormModal";

const EquipmentViewEditForm = ({
  selectedEquipment,
  isEditing = false,
  onSave,
  onCancel,
  onDelete,
}) => {
  const { selectedAsset } = useOutletContext();
  const [selectedEquipmentType, setSelectedEquipmentType] = useState(null);
  const [selectedEquipmentGroup, setSelectedEquipmentGroup] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isUpdateLoading =
    useIsMutating({ mutationKey: ["updateEquipment"] }) +
      useIsMutating({ mutationKey: ["uploadFiles"] }) +
      useIsMutating({ mutationKey: ["deleteMultipleFiles"] }) +
      useIsMutating({ mutationKey: ["deleteFile"] }) >
    0;
  const isDeleteLoading =
    useIsMutating({ mutationKey: ["deleteEquipment"] }) > 0;
  const { data: equipmentTypes, isLoading: equipmentTypesLoading } =
    useGetAllEquipmentTypes({
      pageNumber: null,
      pageSize: null,
      assetCategoryId: selectedAsset?.assetCategory?.id,
    });
  const { data: equipmentClasses, isLoading: equipmentClassesLoading } =
    useGetAllEquipmentClasses({
      pageNumber: null,
      pageSize: null,
      equipmentGroupId: selectedEquipmentGroup?.id,
    });
  const { data: equipmentGroups, isLoading: equipmentGroupsLoading } =
    useGetAllEquipmentGroups({
      pageNumber: null,
      pageSize: null,
      equipmentTypeId: selectedEquipmentType?.id,
    });

  const availableTypesOptions = equipmentTypes?.items?.map((item) => item.name);

  const availableGroupsOptions = equipmentGroups?.items?.map(
    (item) => item.name
  );

  const availableClassesOptions = equipmentClasses?.items?.map(
    (item) => item.name
  );

  const schema = yup.object(
    equipmentResponseFields.reduce((acc, field) => {
      let fieldSchema;

      if (field.type === "text" || field.type === "textarea") {
        fieldSchema = yup.string();
      } else if (field.type === "select" || field.type === "boolean") {
        if (field.name === "status") {
          fieldSchema = yup.object().shape({
            id: yup.string(),
            label: yup.string(),
          });
        } else fieldSchema = yup.string();
      } else if (field.type === "number") {
        fieldSchema = yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value));
      } else if (field.type === "date") {
        fieldSchema = yup.date().transform((value, originalValue) => {
          return isNaN(Date.parse(originalValue)) ? undefined : value;
        });
      } else if (field.type === "file") {
        const validTypes = field.validTypes || [];
        const maxSize = field.maxSize || 5000000;

        fieldSchema = yup.mixed().test({
          name: "fileValidation",
          exclusive: true,
          message: "Invalid file",
          test: (value) => {
            if (!value || value.length === 0) return true;

            for (const file of value) {
              if (
                !validTypes.includes(file.type) &&
                !validTypes.includes(file.mimeType)
              ) {
                return new yup.ValidationError(
                  "Μη έγκυρος τύπος αρχείου",
                  null,
                  "file"
                );
              }

              if (file.size > maxSize) {
                return new yup.ValidationError(
                  `Το μέγεθος του αρχείου δεν πρέπει να υπερβαίνει τα ${maxSize / 1000000}MB`,
                  null,
                  "file"
                );
              }
            }

            return true;
          },
        });
      }

      if (field.required) {
        fieldSchema = fieldSchema.required("Το πεδίο είναι υποχρεωτικό");
      } else {
        fieldSchema = fieldSchema.nullable();
      }

      if (field.validation === "custom" && field.regex) {
        fieldSchema = fieldSchema.matches(field.regex, field.validationMessage);
      }

      acc[field.name] = fieldSchema.typeError(
        field.validationMessage || "Μη έγκυρη τιμή"
      );

      return acc;
    }, {})
  );

  const getDataValueFromField = (fieldName) => {
    const fieldMap = equipmentResponseFields.reduce((acc, field) => {
      acc[field.name] = field.name;
      return acc;
    }, {});

    const path = fieldMap[fieldName];
    if (typeof path === "function") {
      return path();
    } else if (typeof path === "string") {
      const value = path
        .split(".")
        .reduce((acc, key) => (acc ? acc[key] : undefined), selectedEquipment);
      return value !== undefined && value !== null ? value : null;
    } else {
      return "";
    }
  };

  const handleFormSubmit = (data) => {
    onSave(data);
  };

  const defaultValues = {
    ...Object.fromEntries(
      equipmentResponseFields.map((field) => [
        field.name,
        getDataValueFromField(field.name),
      ])
    ),
    equipmentTypeId:
      selectedEquipment?.equipmentClass?.equipmentGroup?.equipmentType?.name,
    equipmentClassId: selectedEquipment?.equipmentClass?.name,
    equipmentGroupId: selectedEquipment?.equipmentClass?.equipmentGroup?.name,
    supplyType: selectedEquipment.isRented ? "Ενοικίαση" : "Αγορά",
    files: getDataValueFromField("files")?.map((file) => ({
      id: file.id,
      name: file.name,
      preview: file.url,
      type: file.mimeType,
      title: file.title,
    })),
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    getValues,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const watchEquipmentTypeId = watch("equipmentTypeId");
  const watchEquipmentGroupId = watch("equipmentGroupId");

  useEffect(() => {
    if (watchEquipmentTypeId) {
      setSelectedEquipmentType(
        equipmentTypes?.items.find((type) => type.name === watchEquipmentTypeId)
      );
    } else {
      setSelectedEquipmentType(null);
    }

    if (watchEquipmentGroupId) {
      setSelectedEquipmentGroup(
        equipmentGroups?.items.find(
          (type) => type.name === watchEquipmentGroupId
        )
      );
    } else {
      setSelectedEquipmentGroup(null);
    }
  }, [
    watchEquipmentTypeId,
    watchEquipmentGroupId,
    equipmentTypes,
    equipmentGroups,
  ]);

  const getInputElementByFieldType = (field) => {
    const defaultValue = getDataValueFromField(field.name);

    if (
      field.type === "text" ||
      field.type === "number" ||
      field.type === "textarea"
    ) {
      return (
        <CustomTextField
          {...field}
          value={defaultValue}
          disabled={!isEditing}
          control={control}
        />
      );
    } else if (field.type === "select" || field.type === "boolean") {
      let availableOptions =
        field.type === "boolean" ? ["Ναι", "Όχι"] : field.options;

      if (!availableOptions) {
        if (field.name === "equipmentTypeId") {
          availableOptions = availableTypesOptions;
        } else if (field.name === "equipmentClassId") {
          availableOptions = availableClassesOptions;
        } else if (field.name === "equipmentGroupId") {
          availableOptions = availableGroupsOptions;
        }
      }

      return (
        <DropdownField
          {...field}
          control={control}
          value={defaultValue}
          disabled={!isEditing}
          options={availableOptions}
          boolean={field.type === "boolean"}
          onChange={(e) => {
            if (field.name === "equipmentTypeId") {
              setValue("equipmentGroupId", null);
              setValue("equipmentClassId", null);
            } else if (field.name === "equipmentGroupId") {
              setValue("equipmentClassId", null);
            }
          }}
        />
      );
    } else if (field.type === "date") {
      return (
        <DateField
          {...field}
          value={defaultValue}
          disabled={!isEditing}
          control={control}
        />
      );
    } else if (field.type === "file") {
      return (
        <AlfrescoFileUploader
          {...field}
          control={control}
          value={defaultValue}
          disabled={!isEditing}
        />
      );
    }
  };

  if (
    equipmentTypesLoading ||
    equipmentClassesLoading ||
    equipmentGroupsLoading
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <SpinningCircle />
      </Box>
    );
  }

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(() => handleFormSubmit(getValues()))}>
        <Box display="flex" flexDirection="column" gap={4}>
          <Grid container spacing={2}>
            {equipmentResponseFields
              .filter((field) => field.display !== false)
              .map((field) => {
                const { name } = field;
                const shouldSkip =
                  (name === "equipmentGroupId" && !selectedEquipmentType) ||
                  (name === "equipmentClassId" &&
                    (!selectedEquipmentGroup || !selectedEquipmentType));

                if (shouldSkip) {
                  return null;
                } else if (field.type === "file") {
                  return (
                    <Grid item xs={12} key={field?.name}>
                      <Box width={{ xs: "100%", lg: "50%" }}>
                        {getInputElementByFieldType(field)}
                      </Box>
                    </Grid>
                  );
                } else
                  return (
                    <Grid item xs={12} md={4} key={field?.name}>
                      {getInputElementByFieldType(field)}
                    </Grid>
                  );
              })}
          </Grid>

          {Object.keys(errors).length > 0 && (
            <Typography color="error" fontSize={14}>
              Παρακαλώ ελέγξτε ότι όλα τα πεδία έχουν συμπληρωθεί σωστά
            </Typography>
          )}

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <Box display="flex" gap={2} justifyContent={"flex-start"}>
              <CancelButton
                onClick={() => {
                  reset();
                  onCancel();
                }}
                disabled={!isEditing}
              />
              <DeleteButton
                onClick={handleOpenModal}
                text="Διαγραφή Εξοπλισμού"
                disabled={!isEditing}
                isLoading={isDeleteLoading}
              />
            </Box>

            <SaveButton
              disabled={!isEditing || !isDirty}
              isLoading={isUpdateLoading}
            />

            <DeleteModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              onDelete={onDelete}
              itemTitle={selectedEquipment?.name}
              title="Πρόκειται να διαγράψετε τον εξοπλισμό"
              description="Είστε βέβαιοι ότι θέλετε να προχωρήσετε στη διαγραφή του εξοπλισμού;"
            />
          </Box>
        </Box>
      </form>

      <ExitFormModal isFormDirty={isDirty} />
    </>
  );
};

EquipmentViewEditForm.propTypes = {
  selectedEquipment: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
};

export default EquipmentViewEditForm;
