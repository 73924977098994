import { Box, Typography, IconButton } from "@mui/material";
import MainPageLayout from "components/main/MainPageLayout";
import CompanyViewEditForm from "./CompanyViewEditForm";
import { useNavigate, useParams } from "react-router";
import { useState } from "react";
import SpinningCircle from "components/spinners/SpinningCircle";
import EditButton from "components/buttons/EditButton";
import {
  useDeleteCompany,
  useGetCompanyById,
  useUpdateCompany,
} from "hooks/queries/useCompanies";
import { useAlert } from "hooks/useAlert";
import ReturnButton from "components/buttons/ReturnButton";
import colors from "config/theme/colors";
import enums from "config/enums";
import CompanyContractsOverview from "pages/contracts/Overview/CompanyContractsOverview";
import { useAuthStore } from "stores/AuthStore";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const CompanyViewEdit = () => {
  const authStore = useAuthStore();
  const navigate = useNavigate();
  const { id } = useParams();
  const { showError, showSuccess } = useAlert();
  const [isDeleted, setIsDeleted] = useState(false);
  const [isHistoryVisible, setIsHistoryVisible] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const {
    data: selectedCompany,
    isLoading,
    isError,
    error,
  } = useGetCompanyById(id, {
    select: (data) => data.result,
    enabled: !!id && !isDeleted,
  });
  const { mutate: deleteCompany } = useDeleteCompany({
    id,
    config: {
      onError: (error) => {
        showError(error.message);
        setIsDeleted(false);
      },
      onSuccess: () => {
        showSuccess("O οργανισμός διαγράφηκε με επιτυχία");
        navigate("/companies");
      },
    },
  });
  const { mutate: updateCompany } = useUpdateCompany({
    id,
    config: {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        showSuccess("O οργανισμός ενημερώθηκε με επιτυχία");
      },
    },
  });

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <SpinningCircle />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>Error: {error.message}</p>
      </Box>
    );
  }

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleSaveClick = (data) => {
    setIsEditing(false);

    const payload = {
      name: data.name,
      description: data.description,
      vat: data.vat,
      contactPerson: data.contactPerson,
      contactPhone: data.contactPhone,
      address: data.address,
      email: data.email,
      type: enums.CompanyTypes?.find((type) => type.label === data.type)?.id,
      comments: data.comments,
    };

    updateCompany(payload);
  };

  const handleDeleteClick = () => {
    setIsDeleted(true);
    setIsEditing(false);
    deleteCompany();
  };

  const toggleHistoryVisibility = () => {
    setIsHistoryVisible((prev) => !prev);
  };

  return (
    selectedCompany && (
      <MainPageLayout pageTitle={"Διαχείριση Οργανισμού"}>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{
            height: "100%",
          }}
        >
          <Box
            mb={2}
            display="flex"
            gap={2}
            justifyContent="space-between"
            alignItems={{
              xs: "flex-start",
              sm: "center",
            }}
            flexWrap={"wrap"}
          >
            <Box
              display="flex"
              gap={2}
              alignItems="center"
              sx={{
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
            >
              <ReturnButton />
              <Typography fontSize={20} fontWeight={600} color={colors.primary}>
                {`${selectedCompany.name}`}
              </Typography>
            </Box>
            {!authStore.isUser && (
              <Box
                display="flex"
                gap={2}
                sx={{
                  width: {
                    xs: "100%",
                    sm: "auto",
                  },
                }}
              >
                <EditButton onClick={handleEditClick} disabled={isEditing} />
              </Box>
            )}
          </Box>
          <Box
            display="flex"
            gap={2}
            justifyContent="space-between"
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                width: `${isHistoryVisible ? "50%" : "100%"}`,
              }}
            >
              <CompanyViewEditForm
                selectedCompany={selectedCompany}
                isEditing={isEditing}
                onSave={handleSaveClick}
                onCancel={handleCancelClick}
                onDelete={handleDeleteClick}
              />
            </Box>
            <Box
              sx={{
                borderLeft: `solid ${isHistoryVisible ? "2px" : "0px"}`,
                borderColor: colors.lightGrey,
                opacity: isHistoryVisible ? "0.2" : "0.5",
                marginLeft: 2,
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton onClick={toggleHistoryVisibility} size="small">
                {isHistoryVisible ? (
                  <ArrowForwardIosIcon />
                ) : (
                  <ArrowBackIosNewIcon />
                )}
              </IconButton>
            </Box>
            {isHistoryVisible && (
              <Box
                sx={{
                  width: "50%",
                  transition: "width 0.3s ease",
                }}
              >
                <CompanyContractsOverview companyId={parseInt(id)} />
              </Box>
            )}
          </Box>
        </Box>
      </MainPageLayout>
    )
  );
};

export default CompanyViewEdit;
