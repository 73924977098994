import { afmRegex, emailRegex, phoneRegex } from "config/regex";

const companyResponseFields = [
  {
    label: "Όνομα",
    name: "name",
    type: "text",
    required: true,
  },
  {
    label: "Περιγραφή",
    name: "description",
    type: "text",
    required: true,
  },
  {
    label: "ΑΦΜ",
    name: "vat",
    type: "text",
    required: true,
    validation: "custom",
    regex: afmRegex,
    validationMessage: "Παρακαλώ εισάγετε έγκυρο ΑΦΜ",
  },
  {
    label: "Υπεύθυνος",
    name: "contactPerson",
    type: "text",
    required: true,
  },
  {
    label: "Τηλέφωνο",
    name: "contactPhone",
    type: "text",
    required: true,
    validation: "custom",
    regex: phoneRegex,
    validationMessage: "Ο αριθμός τηλεφώνου που καταχωρήσατε δεν είναι έγκυρος",
  },
  {
    label: "Διεύθυνση",
    name: "address",
    type: "text",
    required: true,
  },
  {
    label: "Email",
    name: "email",
    type: "text",
    required: true,
    validation: "custom",
    regex: emailRegex,
    validationMessage: "Παρακαλώ εισάγετε μια έγκυρη διεύθυνση email",
  },
  {
    label: "Τύπος",
    name: "type",
    type: "select",
    required: true,
    options: [],
  },
  {
    label: "Σχόλια",
    name: "comments",
    type: "textarea",
    required: false,
  },
];

export default companyResponseFields;
