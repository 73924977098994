import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Agent } from "api/agent";

export const useGetAllMaintenancesHistory = (
  { pageNumber = -1, pageSize = -1, maintenanceId },
  options = {}
) => {
  return useQuery({
    queryKey: [
      "getAllMaintenancesHistory",
      pageNumber,
      pageSize,
      maintenanceId,
    ],
    queryFn: () =>
      Agent.maintenanceHistory.getAll({ pageNumber, pageSize, maintenanceId }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetMaintenanceHistoryById = (
  maintenanceHistoryId,
  options = {}
) => {
  return useQuery({
    queryKey: ["getMaintenanceHistoryById", +maintenanceHistoryId],
    queryFn: () => Agent.maintenanceHistory.getById(maintenanceHistoryId),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};

export const useCreateMaintenanceHistory = ({ config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: ["createMaintenanceHistory"],
    mutationFn: (body) => Agent.maintenanceHistory.create(body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries(["getAllMaintenancesHistory"]);
      queryClient.invalidateQueries(["getAllTasks"]);
      queryClient.invalidateQueries(["getTaskById"]);
      queryClient.invalidateQueries(["getMaintenanceById"]);
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useAddMaintenanceHistoryInMultipleMaintenances = ({
  config = {},
}) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: ["addMaintenanceHistoryInMultipleMaintenances"],
    mutationFn: (body) =>
      Agent.maintenanceHistory.addMaintenanceHistoryInMultipleMaintenances(
        body
      ),
    onSuccess: (...args) => {
      queryClient.invalidateQueries(["getAllMaintenancesHistory"]);
      queryClient.invalidateQueries(["getAllTasks"]);
      queryClient.invalidateQueries(["getTaskById"]);
      queryClient.invalidateQueries(["getMaintenanceById"]);
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useUpdateMaintenanceHistory = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;
  return useMutation({
    mutationKey: ["updateMaintenanceHistory"],
    mutationFn: (body) => Agent.maintenanceHistory.update(id, body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries(["getAllMaintenancesHistory"]);
      queryClient.invalidateQueries(["getMaintenanceHistoryById", +id]);
      queryClient.invalidateQueries(["getAllTasks"]);
      queryClient.invalidateQueries(["getTaskById"]);
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useDeleteMaintenanceHistory = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config;

  return useMutation({
    mutationKey: ["deleteMaintenanceHistory"],
    mutationFn: () => Agent.maintenanceHistory.delete(id),
    onSuccess: (...args) => {
      queryClient.invalidateQueries(["getAllMaintenancesHistory"]);
      queryClient.invalidateQueries(["getMaintenanceHistoryById", +id]);
      queryClient.invalidateQueries(["getAllTasks"]);
      queryClient.invalidateQueries(["getTaskById"]);
      queryClient.invalidateQueries(["getMaintenanceById"]);
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};
