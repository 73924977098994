import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SpinningCircle from "components/spinners/SpinningCircle";
import CustomTable from "components/tables/CustomTable";
import { useMemo, useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router";
import FilterButton from "components/buttons/FilterButton";
import usePagination from "hooks/usePagination";
import dayjs from "dayjs";
import StatusLabel from "components/labels/StatusLabel";
import enums from "config/enums";
import PropTypes from "prop-types";
import { useGetEquipmentMaintenancesByAssetId } from "hooks/queries/useMaintenances";
import CustomButton from "components/buttons/CustomButton";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import EquipmentMaintenancesMassImportStepperModal from "../MassImport/EquipmentMaintenancesMassImportStepperModal";
import UserInputs from "../MassImport/Steps/UserInputs";
import EquipmentsSelectionTable from "../MassImport/Steps/EquipmentsSelectionTable";
import EquipmentsMaintenancesCheck from "../MassImport/Steps/EquipmentsMaintenancesCheck";
import EquipmentsMaintenanceHistoryCreation from "../MassImport/Steps/EquipmentsMaintenanceHistoryCreation";
import colors from "config/theme/colors";
import EquipmentMaintenanceNewStepperModal from "../CreateEquipmentMaintenanceModal/EquipmentMaintenanceNewStepperModal";
import SelectEquipmentType from "../CreateEquipmentMaintenanceModal/Steps/SelectEquipmentType";
import CreateEquipmentMaintenance from "../CreateEquipmentMaintenanceModal/Steps/CreateEquipmentMaintenance";
import { Button } from "@mui/material";
import OpenInBrowserOutlinedIcon from "@mui/icons-material/OpenInBrowserOutlined";
import { useGetAllCompanies } from "hooks/queries/useCompanies";

const EquipmentMaintenancesOverview = ({
  assetId = null,
  maintenanceTypes = [],
  equipmentTypes = [],
}) => {
  const createEquipmentMaintenanceRef = useRef(null);
  const navigate = useNavigate();
  const {
    page,
    setPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination();
  const [selectedFilters, setSelectedFilters] = useState({});
  const [
    isEquipmentMaintenancesMassImportModalOpen,
    setIsEquipmentMaintenancesMassImportModalOpen,
  ] = useState(false);
  const [
    isEquipmentMaintenanceNewModalOpen,
    setIsEquipmentMaintenanceNewModalOpen,
  ] = useState(false);
  const [selectedMaintenanceTypeIds, setSelectedMaintenanceTypeIds] = useState(
    []
  );
  const [selectedEquipmentTypeIds, setSelectedEquipmentTypeIds] = useState([]);
  const [selectedEquipmentType, setSelectedEquipmentType] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedContract, setSelectedContract] = useState(null);
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const [
    disableNextButtonForMaintenanceCheck,
    setDisableNextButtonForMaintenanceCheck,
  ] = useState(true);
  const {
    data: equipmentMaintenances,
    isLoading: isEquipmentMaintenancesLoading,
    error: errorEquipmentMaintenances,
    refetch: refetchEquipmentMaintenances,
  } = useGetEquipmentMaintenancesByAssetId({
    pageNumber: page,
    pageSize: rowsPerPage,
    assetId: assetId,
    maintenanceTypeIds: selectedMaintenanceTypeIds,
    equipmentTypeIds: selectedEquipmentTypeIds,
  });
  const {
    data: companies,
    isLoading: isCompaniesLoading,
    error: errorCompanies,
  } = useGetAllCompanies({}, { select: (data) => data.result?.items || [] });

  const equipmentsIds = selectedEquipments?.map((equipment) => equipment.id);
  const contractId = selectedContract?.id;

  const reset = () => {
    setSelectedEquipments([]);
    setSelectedEquipmentType(null);
    setSelectedCompany(null);
    setSelectedContract(null);
    refetchEquipmentMaintenances();
  };

  const handleOpenEquipmentMaintenancesMassImportModal = () => {
    setIsEquipmentMaintenancesMassImportModalOpen(true);
  };

  const handleCloseEquipmentMaintenancesMassImportModal = () => {
    setIsEquipmentMaintenancesMassImportModalOpen(false);
    reset();
  };

  const handleOpenEquipmentNewMaintenanceModal = () => {
    setIsEquipmentMaintenanceNewModalOpen(true);
  };

  const handleCloseEquipmentNewMaintenanceModal = () => {
    setIsEquipmentMaintenanceNewModalOpen(false);
    reset();
  };

  const massImportSteps = [
    {
      id: 1,
      component: (
        <UserInputs
          equipmentTypes={equipmentTypes}
          selectedEquipmentType={selectedEquipmentType}
          setSelectedEquipmentType={setSelectedEquipmentType}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          selectedContract={selectedContract}
          setSelectedContract={setSelectedContract}
        />
      ),
      disableNextButton:
        !selectedEquipmentType || !selectedCompany || !selectedContract,
    },
    {
      id: 2,
      component: (
        <EquipmentsSelectionTable
          selectedEquipmentType={selectedEquipmentType}
          assetId={assetId}
          selectedEquipments={selectedEquipments}
          setSelectedEquipments={setSelectedEquipments}
        />
      ),
      disableNextButton: selectedEquipments?.length === 0,
    },
    {
      id: 3,
      component: (
        <EquipmentsMaintenancesCheck
          equipmentsIds={equipmentsIds}
          contractId={contractId}
          setDisableNextButton={setDisableNextButtonForMaintenanceCheck}
          selectedEquipmentType={selectedEquipmentType}
        />
      ),
      disableNextButton: disableNextButtonForMaintenanceCheck,
    },
    {
      id: 4,
      component: (
        <EquipmentsMaintenanceHistoryCreation
          equipmentsIds={equipmentsIds}
          contractId={contractId}
          afterFinalSubmission={reset}
        />
      ),
    },
  ];

  const newEquipmentMaintenanceSteps = [
    {
      id: 1,
      component: (
        <SelectEquipmentType
          equipmentTypes={equipmentTypes}
          selectedEquipmentType={selectedEquipmentType}
          setSelectedEquipmentType={setSelectedEquipmentType}
          selectedEquipment={selectedEquipments[0]}
          setSelectedEquipment={(equipment) =>
            setSelectedEquipments([equipment])
          }
        />
      ),
      disableNextButton: !selectedEquipmentType || !selectedEquipments[0],
    },
    {
      id: 2,
      component: (
        <CreateEquipmentMaintenance
          ref={createEquipmentMaintenanceRef}
          selectedEquipment={selectedEquipments[0]}
          selectedEquipmentType={selectedEquipmentType}
        />
      ),
      nextButtonText: "Δημιουργία",
      onNextCallback: () => {
        return createEquipmentMaintenanceRef.current
          ?.submitForm()
          .then(() => {
            return Promise.resolve();
          })
          .catch(() => {
            return Promise.reject(new Error("Υπήρξε πρόβλημα"));
          });
      },
    },
    {
      id: 3,
      component: (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            height: "100%",
          }}
        >
          <Typography variant="h4" textAlign={"center"}>
            Η συντήρηση δημιουργήθηκε με επιτυχία
          </Typography>
        </Box>
      ),
      disableBackButton: true,
      nextButtonText: "Ολοκλήρωση",
      onNextCallback: handleCloseEquipmentNewMaintenanceModal,
    },
  ];

  const filterGroups = [
    {
      title: "Τύπος Συντήρησης",
      filters: maintenanceTypes?.map((type) => ({
        id: type.id,
        label: type.name,
        type: "checkbox",
      })),
    },
    {
      title: "Τύπος Εξοπλισμού",
      filters: equipmentTypes?.map((type) => ({
        id: type.id,
        label: type.name,
        type: "checkbox",
      })),
    },
  ];

  const handleSubmitFilters = useCallback(
    (filters) => {
      const maintenanceTypeIds = filters["Τύπος Συντήρησης"] || [];
      const equipmentTypeIds = filters["Τύπος Εξοπλισμού"] || [];
      setSelectedFilters(filters);
      setSelectedMaintenanceTypeIds(maintenanceTypeIds);
      setSelectedEquipmentTypeIds(equipmentTypeIds);
      if (filters) {
        setPage(1);
      }

      if (maintenanceTypeIds.length === 0 && equipmentTypeIds.length === 0) {
        refetchEquipmentMaintenances();
      }
    },
    [
      setSelectedFilters,
      setSelectedMaintenanceTypeIds,
      setSelectedEquipmentTypeIds,
      refetchEquipmentMaintenances,
      setPage,
    ]
  );

  const tableHeaders = useMemo(
    () => [
      {
        id: "name",
        label: "Όνομα",
        isSortable: false,
      },
      {
        id: "equipment",
        label: "Όνομα Eξοπλισμού",
        isSortable: false,
      },
      {
        id: "contract",
        label: "Σύμβαση",
        isSortable: false,
      },
      {
        id: "company",
        label: "Οργανισμός",
        isSortable: false,
      },
      {
        id: "frequency",
        label: "Συχνότητα (μέρες)",
        isSortable: false,
      },
      {
        id: "lastDate",
        label: "Ημ/νία Τελευταίας Συντήρησης",
        isSortable: false,
      },
      {
        id: "nextDate",
        label: "Ημ/νία Επόμενης Συντήρησης",
        isSortable: false,
      },
      {
        id: "maintenanceType",
        label: "Τύπος Συντήρησης",
        isSortable: false,
      },
      {
        id: "status",
        label: "Κατάσταση",
        isSortable: false,
      },
    ],
    []
  );

  if (isEquipmentMaintenancesLoading || isCompaniesLoading) {
    return <SpinningCircle />;
  }

  if (errorEquipmentMaintenances || errorCompanies) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>
          Error:{" "}
          {errorEquipmentMaintenances?.message || errorCompanies?.message}
        </p>
      </Box>
    );
  }

  const equipmentMaintenancesCount = equipmentMaintenances?.totalCount;
  const equipmentMaintenancesList = equipmentMaintenances?.items?.map(
    (equipmentMaintenance) => ({
      ...equipmentMaintenance,
      name: equipmentMaintenance?.name || "-",
      equipment: equipmentMaintenance?.equipment?.name || "-",
      frequency: equipmentMaintenance?.frequency || "-",
      maintenanceType: equipmentMaintenance?.maintenanceType?.name || "-",
      contract: equipmentMaintenance?.contract?.name || "-",
      company: equipmentMaintenance?.contract?.externalCompanyId ? (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            navigate(
              `/companies/${equipmentMaintenance?.contract?.externalCompanyId}`
            );
          }}
          sx={{
            textTransform: "none",
            fontSize: 14,
            fontWeight: 700,
            color: colors.text,
            backgroundColor: "transparent",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          <Box
            component="span"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={1}
          >
            {
              companies?.find(
                (company) =>
                  company.id ===
                  equipmentMaintenance?.contract?.externalCompanyId
              )?.name
            }
            <OpenInBrowserOutlinedIcon fontSize={"small"} />
          </Box>
          {}
        </Button>
      ) : (
        "-"
      ),
      lastDate: equipmentMaintenance?.lastDate
        ? dayjs(equipmentMaintenance?.lastDate).format("DD/MM/YYYY")
        : "-",
      nextDate:
        equipmentMaintenance?.manualNextMaintenanceDate ||
        equipmentMaintenance?.nextMaintenanceDate
          ? dayjs(
              equipmentMaintenance?.manualNextMaintenanceDate ||
                equipmentMaintenance?.nextMaintenanceDate
            ).format("DD/MM/YYYY")
          : "-",
      status: equipmentMaintenance?.status ? (
        <StatusLabel
          statusValue={equipmentMaintenance?.status}
          statusText={enums.MaintenanceStatus[equipmentMaintenance?.status]}
        />
      ) : (
        "-"
      ),
    })
  );

  const handleItemClick = (item) => {
    navigate(
      `/assets/${assetId}/equipment/${item.equipmentId}/maintenance/${item.id}`
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={4}
      sx={{
        height: "100%",
      }}
    >
      <Box
        display="flex"
        flexDirection={{
          xs: "column",
          sm: "row",
        }}
        justifyContent={{
          xs: "space-between",
        }}
        flexGrow={1}
        flexWrap={{
          xs: "wrap",
          md: "nowrap",
        }}
        width={"100%"}
        gap={2}
      >
        <Box
          display="flex"
          gap={2}
          flexWrap={{
            xs: "wrap",
            sm: "nowrap",
          }}
          sx={{
            overflowX: "auto",
          }}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flexGrow={1}
        gap={2}
        width={"100%"}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={4}
          flexWrap={"wrap"}
        >
          <Typography variant="h3" color="primary">
            Συντηρήσεις Εξοπλισμού ({equipmentMaintenancesCount})
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              flexGrow: 1,
              gap: 2,
            }}
          >
            <FilterButton
              selectedFilters={selectedFilters}
              filterGroups={filterGroups}
              onApplyFilters={handleSubmitFilters}
            />

            <CustomButton
              icon={<UploadFileOutlinedIcon />}
              title="Μαζική Προσθήκη"
              orientation="row"
              fontSize={13}
              fontWeight={700}
              sx={{
                minHeight: "33px",
                maxHeight: "33px",
                borderRadius: 3,
                paddingY: 2.3,
              }}
              onClick={handleOpenEquipmentMaintenancesMassImportModal}
            />

            <CustomButton
              icon={<AddIcon fill={colors.primary} width={20} height={20} />}
              title="Προσθήκη"
              orientation="row"
              fontSize={13}
              fontWeight={700}
              sx={{
                minHeight: "33px",
                maxHeight: "33px",
                borderRadius: 3,
                paddingY: 2.3,
              }}
              onClick={handleOpenEquipmentNewMaintenanceModal}
            />
          </Box>
        </Box>

        {equipmentMaintenancesList.length > 0 &&
        tableHeaders &&
        equipmentMaintenancesCount > 0 ? (
          <CustomTable
            columns={tableHeaders}
            data={equipmentMaintenancesList}
            fadeEffect
            onItemClick={handleItemClick}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalResults={equipmentMaintenancesCount}
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
            }}
          >
            <Typography variant="h4">Δεν υπάρχουν αποτελέσματα</Typography>
          </Box>
        )}

        <EquipmentMaintenancesMassImportStepperModal
          isOpen={isEquipmentMaintenancesMassImportModalOpen}
          handleClose={handleCloseEquipmentMaintenancesMassImportModal}
          steps={massImportSteps}
          setSelectedEquipments={setSelectedEquipments}
          setSelectedEquipmentType={setSelectedEquipmentType}
          setSelectedCompany={setSelectedCompany}
          setSelectedContract={setSelectedContract}
        />

        <EquipmentMaintenanceNewStepperModal
          isOpen={isEquipmentMaintenanceNewModalOpen}
          handleClose={handleCloseEquipmentNewMaintenanceModal}
          steps={newEquipmentMaintenanceSteps}
          setSelectedEquipment={(equipment) =>
            setSelectedEquipments([equipment])
          }
          setSelectedEquipmentType={setSelectedEquipmentType}
        />
      </Box>
    </Box>
  );
};

EquipmentMaintenancesOverview.propTypes = {
  assetId: PropTypes.number,
  maintenanceTypes: PropTypes.array,
  equipmentTypes: PropTypes.array,
};

export default EquipmentMaintenancesOverview;
