const maintenanceResponseFields = {
  maintenance: [
    {
      label: "Όνομα",
      name: "name",
      type: "text",
      required: true,
    },
    {
      label: "Περιγραφή",
      name: "description",
      type: "textarea",
      required: false,
    },
    {
      label: "Συχνότητα (μέρες)",
      name: "frequency",
      type: "number",
      required: true,
    },
    {
      label: "Επόμενη συντήρηση",
      name: "nextDate",
      type: "date",
      editable: false,
    },
    {
      label: "Τύπος συντήρησης",
      name: "maintenanceTypeId",
      type: "select",
      required: true,
      options: [],
    },
    {
      label: "Σύμβαση",
      name: "contractId",
      type: "select",
      required: false,
      options: [],
    },
    {
      label: "Οργανισμός",
      name: "company",
      type: "text",
      required: false,
      editable: false,
    },
    {
      label: "Σημειώσεις",
      name: "notes",
      type: "textarea",
      required: false,
    },
    {
      label: "Κατάσταση",
      name: "status",
      type: "select",
      required: true,
      options: [],
    },
    {
      label: "Αρχεία",
      name: "files",
      type: "file",
      multiple: true,
      validTypes: ["image/jpeg", "image/png", "application/pdf"],
      maxSize: 5000000,
    },
  ],
  maintenanceHistory: [
    {
      label: "Όνομα",
      name: "name",
      type: "text",
      required: true,
    },
    {
      label: "Περιγραφή",
      name: "description",
      type: "text",
      required: false,
    },
    {
      label: "Ημερομηνία",
      name: "date",
      type: "date",
      required: true,
    },
    {
      label: "Ποσό (€)",
      name: "amount",
      type: "number",
      required: true,
    },
    {
      label: "Σύμβαση",
      name: "contractId",
      type: "text",
      required: false,
      editable: false,
    },
    {
      label: "Σημειώσεις",
      name: "notes",
      type: "textarea",
      required: false,
    },
  ],
};

export default maintenanceResponseFields;
