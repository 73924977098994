import React from "react";
import { observer } from "mobx-react-lite";
import { useAuthStore } from "stores/AuthStore";
import { Box, Typography } from "@mui/material";
import colors from "config/theme/colors";

const THRESHOLD = 300; // 5 minutes

const CountdownTimer = observer(() => {
  const { countdownValue } = useAuthStore();

  if (countdownValue === null || countdownValue > THRESHOLD) return null;

  const minutes = Math.floor(countdownValue / 60);
  const seconds = countdownValue % 60;

  return (
    <Box
      position="fixed"
      top={16}
      left="50%"
      bgcolor={colors.lightGrey}
      color="#fff"
      p={2}
      borderRadius={2}
      boxShadow={3}
      zIndex={1300}
      display="flex"
      alignItems="center"
      justifyContent="center"
      minWidth={200}
      sx={{
        transform: "translateX(-25%)",
        opacity: 0.9,
      }}
    >
      <Typography mr={1} fontSize={16}>
        Η συνεδρία σας λήγει σε:
      </Typography>
      <Typography aria-live="assertive" fontSize={16}>
        {minutes < 10 ? `0${minutes}` : minutes}:
        {seconds < 10 ? `0${seconds}` : seconds}
      </Typography>
    </Box>
  );
});

export default CountdownTimer;
