import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Agent } from "api/agent";

export const useGetAllEquipment = (
  {
    pageNumber = -1,
    pageSize = -1,
    assetId,
    assetCategoryId,
    equipmentTypeIds = [],
    orderBy,
    sortingOrder,
    searchParameter,
  } = {
    pageNumber: -1,
    pageSize: -1,
    assetId: null,
    assetCategoryId: null,
    equipmentTypeIds: [],
    orderBy: "",
    sortingOrder: "",
    searchParameter: "",
  },
  options = {}
) => {
  return useQuery({
    queryKey: [
      "getAllEquipment",
      pageNumber,
      pageSize,
      assetId,
      assetCategoryId,
      equipmentTypeIds,
      orderBy,
      sortingOrder,
      searchParameter,
    ],
    queryFn: () =>
      Agent.equipment.getEquipmentList({
        pageNumber: pageNumber,
        pageSize: pageSize,
        assetId: assetId,
        assetCategoryId: assetCategoryId,
        equipmentTypeIds,
        orderBy,
        sortingOrder,
        searchParameter,
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const useGetEquipmentById = (equipmentId, options = {}) => {
  return useQuery({
    queryKey: ["getEquipmentById", +equipmentId],
    queryFn: () => Agent.equipment.getEquipmentById(equipmentId),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};

export const useGetEquipmentTypeCount = (options = {}) => {
  return useQuery({
    queryKey: ["getEquipmentTypeCount"],
    queryFn: () => Agent.equipment.getEquipmentTypeCount(),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};

export const useCreateEquipment = ({ config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config || {};

  return useMutation({
    mutationKey: ["createEquipment"],
    mutationFn: (body) => Agent.equipment.createEquipment(body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries(["getAllEquipment"]);
      queryClient.invalidateQueries(["getEquipmentsByAssetId"]);
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useUpdateEquipment = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config || {};

  return useMutation({
    mutationKey: ["updateEquipment"],
    mutationFn: (body) => Agent.equipment.updateEquipment(id, body),
    onSuccess: (...args) => {
      queryClient.invalidateQueries(["getAllEquipment"]);
      queryClient.invalidateQueries(["getEquipmentsByAssetId"]);
      queryClient.invalidateQueries(["getEquipmentById", +id]);
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};

export const useDeleteEquipment = ({ id, config = {} }) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restConfig } = config || {};

  return useMutation({
    mutationKey: ["deleteEquipment"],
    mutationFn: () => Agent.equipment.deleteEquipment(id),
    onSuccess: (...args) => {
      queryClient.invalidateQueries(["getAllEquipment"]);
      queryClient.invalidateQueries(["getEquipmentsByAssetId"]);
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};
